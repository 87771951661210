@import "variables";
.subtitle_in_form {
  margin-top: 0px;
  margin-bottom: 20px;
}

.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 0 4%;
}

.checkbox_label {
  padding-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #5d0282;
}

.email_text {
  font-family: Soleil-Regular;
  font-size: 13px;
  margin-top: 5px;
}

.container {
  .checkbox_container {
    display: flex;
    flex-direction: column;
  }
}

.submit_btn_to_right {
  display: flex;
  justify-content: end;
  padding-bottom: 32px;
  button {
    text-transform: lowercase;
    max-width: 320px;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 4%;
  }
}
