@import "variables";

.dropdown_customized {
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  .rw-widget-input {
    .rw-dropdown-list-input .rw-dropdown-list-value {
      margin-top: 5px;
    }
  }

  // flex: 1;
  // display: block;
}

.container {
  background: #f5f7fa;

  h1 {
    max-width: 100%;
    padding-bottom: 32px;
  }

  .buttonSubmit {
    max-width: 400px;
    height: 48px;
    background: #5d0282;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #ffffff;
    font-family: "Soleil-Regular";
    /*Can be any text*/
    padding: 0 20px;
    border: none;
    cursor: pointer;
  }

  .wrapper {
    padding: 0 60px 60px;
    display: flex;
    gap: 60px;
    overflow-x: auto;

    &__colLeft {
      max-width: 550px;
      flex: 0 0 550px;

      .header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 22px;

        .headerTitle {
          font-size: 32px;
        }
      }
      .label_plus_input_container {
        display: flex;
        flex-direction: column;
        // margin-bottom: 24px;

        .special_label {
          color: grey;
          font-size: 11px;
          font-weight: bolder;
        }

        input {
          padding: 6px 10px;
        }
      }
      .boxWrapper {
        background-color: #fff;
        padding: 32px 20px;
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .formGroup {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
          max-width: 330px;
          width: 100%;

          label {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 600;
            color: #5d0282;
          }
          .error {
            color: #ea001e;
            font-size: 12px;
            line-height: 18px;
          }

          .errorInput {
            border: 2px solid #ea001e;
            outline: none;
          }
        }
        .subTitle {
          font-size: 18px;
          margin-bottom: 22px;
          font-family: "Inseec-Medium";
          /*Can be any text*/
          line-height: 1.5;
        }

        .listInfo {
          display: flex;
          flex-direction: column;
          margin-bottom: 32px;
          max-width: 330px;
          width: 100%;

          .info {
            font-size: 13px;
            color: #222222;
          }

          &__name {
            font-size: 14px;
            font-weight: 600;
            color: #5d0282;
            display: block;

            &:after {
              content: "";
              width: 100%;
              height: 1px;
              display: block;
              margin: 6px 0;
              background-color: #9f9eb7;
            }
          }
        }
      }

      .action {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.infoBulle {
  position: relative;
  display: inline-block;
  margin-left: 5px;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: #135f90;
    }
  }

  .infoBulleInfo {
    display: none;
    background: #135f90;
    color: #fff;
    font-size: 13px;
    padding: 10px;
    border-radius: 3px;
    position: absolute;
    width: auto;
    min-width: 340px;
    top: -70px;
    right: -10px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px 9.5px 0 11.5px;
      border-color: #135f90 transparent transparent transparent;
      border-style: solid;
      position: absolute;
      bottom: -11px;
      right: 10px;
    }
  }
  &:hover {
    .infoBulleInfo {
      display: block;
    }
  }
  @media screen and (max-width: 768px) {
    .infoBulleInfo {
      width: 250px;
      min-width: 340px;
      top: -60px;
    }
  }
}

.label_plus_info_bulle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.output_plus_image_Container {
  display: flex;
  padding: 5px;
  font-size: 13px;
  line-height: 1.5;

  li {
    color: #4d4d4d;
  }
}

.search_container {
  position: relative;
}

.search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.confirmation_container {
  max-width: 550px;
  background-color: #467dd7;
  padding: 12px 12px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  margin: 20px 0;
  div {
    position: relative;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  p {
    max-width: 80%;
    font-size: 13px;
    color: #ffffff;
    line-height: 1.5;
  }
}
// Mobile

@media screen and (max-width: 1350px) {
  .container {
    .wrapper {
      flex-direction: column;
    }
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .wrapper {
      flex-direction: column;
      padding: 0;
      margin-bottom: 50px;

      &__colLeft {
        max-width: 100%;
        flex: 1;

        .header {
          .headerTitle {
            padding: 0 20px;
          }
        }

        .boxWrapper {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
