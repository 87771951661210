@import "variables";
.wrapper {
	&__colRight {
		background-color: #fff;
		flex: 0 0 28%;
		// max-width: 425px;
		box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
		padding: 14px 28px;
		align-self: flex-start;
		margin-top: 150px;
		.boxRecap {
			h4 {
				font-size: 18px;
				padding-right: 48px;
				line-height: 27px;
				margin-bottom: 22px;
				font-family: "Inseec-Medium"; /*Can be any text*/
			}
			.listInfo {
				display: flex;
				flex-direction: column;
				margin-bottom: 32px;
				.info {
					font-size: 13px;
					color: #222222;
				}
				&__name {
					font-size: 14px;
					font-weight: 600;
					color: #5d0282;
					display: block;
					&:after {
						content: "";
						width: 100%;
						height: 1px;
						display: block;
						margin: 6px 0;
						background-color: #9f9eb7;
					}
				}
			}
		}
	}
}

@media screen and (max-width: 1350px) {
	.wrapper {
		&__colRight {
			margin-top: 5%;
			width: 100%;
			max-width: 550px;
		}
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.wrapper {
		flex-direction: column;
		padding: 0;
		margin-bottom: 50px;
		&__colRight {
			max-width: 100%;
			flex: 1;
			width: 100%;
		}
	}
}
