@import "variables";
.info_Text{
  color:#FF7F00;
  font-size: 14px;
}
.info_bulle {
  position: relative;
  display: inline-block;
  margin-top: 10px;
  svg {
    width: 11px;
    height: 11px;
    path {
      fill: #FF7F00;
    }
  }

  .infoBulleInfo {
    display: none;
    background: #135f90;
    color: #fff;
    font-size: 13px;
    padding: 10px;
    border-radius: 3px;
    position: absolute;
    width: 320px;
    top: -125px;
    right: -10px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px 9.5px 0 11.5px;
      border-style: solid;
      position: absolute;
      bottom: -11px;
      right: 10px;
    }
  }
  &:hover {
    .infoBulleInfo {
      display: block;
    }
  }
}

.main_container {
  background-color: #f8f8fc;
  padding: 24px 32px 0px;
  justify-content: space-between;
  margin-top: 30px;
  border: 2px solid;
  border-radius: 5px;
  min-height: 136px;
}

.section_title {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  cursor: pointer;

  .title_and_optional_container {
    max-width: 85%;

    h1 {
      max-width: 85%;
    }

    .optional {
      margin-top: 15px;
      font-size: 14px;
    }
  }
}

.title {
  font-family: Inseec-Medium;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin-bottom: 5px;
}

.top_section {
  padding-bottom: 15px;
}

.pin_status_success {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  background-color: green;
  margin-top: 3px;
}
.pin_status_pending {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  background-color: grey;
  margin-top: 5px;
}
.pin_status_error {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 10px;
  background-color: red;
  margin-top: 5px;
}

.dropdown_icon {
  margin-left: auto;
  img {
    width: 12px;
    height: 8px;
  }
  .arrowUp {
    transform: rotate(180deg);
  }
}

.subTitle {
  font-family: Soleil-Regular;
  font-weight: 200;
  font-size: 15px;
  color: black;
}

@media screen and (max-width: $mobile-breakpoint) {
  .section_title {
    align-items: center;
  }
  .dropdown_icon {
    img {
      width: 12px;
      height: 8px;
    }
  }
}

@media screen and (max-width: 425px) {
  .section_title {
    align-items: center;
    .title {
      inline-size: 170px;
      overflow: hidden;
    }
  }
}
