@import "variables";

.container {
  h6 {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    color: #5d0282;
  }
  p {
    font-size: 13px;
    color: #222222;
    font-weight: 400;
  }
  .containerBox {
    background-color: #f8f8fc;
    padding: 24px 32px;
    margin: 0 60px 60px;
    .name {
      font-weight: 500;
      font-size: 32px;
      line-height: 130%;
      color: #06073a;
      margin-bottom: 22px;
    }
    .subtitle {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      font-family: "Inseec-Medium";
      margin-bottom: 25px;
      color: #222;
    }
    .box {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 32px;
      margin-bottom: 32px;
      &.flex {
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 50px;
      }
    }
  }
  .flexBox {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .listMission {
    margin-bottom: 22px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .containerBox {
      .box {
        grid-template-columns: 1fr;
        gap: 32px;
        margin: 0 0 32px;
        &.flex {
          grid-template-columns: 1fr;
        }
      }
    }
    .flexBox {
      width: 100%;
    }
  }
}
