@import "variables";

@media screen and (max-width: $mobile-breakpoint) {
  .main_container {
    padding: 0;
  }

  .blue_banner_container {
    padding: 0 10px 10px 10px;
  }
}
