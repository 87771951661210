@import "../../../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    .main_container{
        padding: 0;
    }

    .title_container {
        padding: 0 30px;
    }

    .offer_container {
        margin-top: 32px;
        padding: 16px;
        flex-direction: column;
        width: 100%;
    }
}