.infoBulle {
    position: relative;
    display: inline-block;
    margin-left: 5px;
    svg {
      width: 15px;
      height: 15px;
      path {
        fill: #135f90;
      }
    }
  
    .infoBulleInfo {
      display: none;
      background: #135f90;
      color: #fff;
      font-size: 13px;
      padding: 10px;
      border-radius: 3px;
      position: absolute;
      width: 320px;
      top: -125px;
      right: -10px;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-width: 12px 9.5px 0 11.5px;
        border-color: #135f90 transparent transparent transparent;
        border-style: solid;
        position: absolute;
        bottom: -11px;
        right: 10px;
      }
    }
    &:hover {
      .infoBulleInfo {
        display: block;
      }
    }
  }
  .checkbox_part {
    padding-top: 30px;
    //margin-top: 30px;
  }
  .checkbox_row {
    display: flex;
    flex-direction: row;
  }
  
  .checkbox_container {
    margin: 0px 0px 35px 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    .checkbow_row{
      display: flex;
      align-items: flex-start;
    }
  }
  
  .checkbox_text {
    margin-left: 5px;
    font-family: Soleil-Leger;
    line-height: 1.5;
    font-size: 14px;
  }
  .modal {
    /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .tmp {
    padding-left: 3.8rem;
    padding-right: 3.8rem;
  }
  
  /* Modal Content/Box */
  .modal_content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    max-width: 60%;
    height: auto;
  }
  
  .loading_modal_content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    border-radius: 5px;
    width: 50%;
    height: auto;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .button_container {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
  
  .close_btn {
    background-color: #0099DE;
    max-width: 280px;
    height: 35px;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0px, 16px;
    cursor: pointer;
    color: #fefefe;
    position: relative;
    float: right;
  }
  
  .ok_close_btn {
      background-color: #0099DE;
    width: 50px;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0px, 16px;
    cursor: pointer;
    color: #fefefe;
  }
  
  .true_btn {
      background-color: #0099DE;
    max-width: 150px;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0px, 16px;
    cursor: pointer;
    color: #fefefe;
    margin-right: 10%;
  }
  .false_btn {
      background-color: #0099DE;
    max-width: 150px;
    // margin-left: 2%;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0px, 16px;
    cursor: pointer;
    color: #fefefe;
  }
  .bottom_container {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: space-between;
  }
  
  .close_loading_modal {
      background-color: #0099DE;
    width: 100px;
    //margin-left: 75%;
    border-radius: 0.25rem;
    text-align: center;
    padding: 0px, 16px;
    cursor: pointer;
    color: #fefefe;
    //justify-content: flex-end;
  }
  
  .close_text {
    position: relative;
      display: inline-flex;
    font-family: Soleil-Regular;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .modal_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    text-align: center;
    font-family: Inseec-Medium;
    font-weight: 500;
    font-size: 32px;
    line-height: 130%;
    color: #06073a;
  }
  
  
  .modal_close_button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    margin-left: auto;
  }
  
  
  .closeIcon_size {
    width: 1rem;
    height: 1rem;
  }
  
  .separator_top {
    max-width: 100%;
    height: 2px;
    background-color: grey;
    margin-top: 30px;
    opacity: 0.5;
  }
  
  
  .separator_bottom {
    max-width: 100%;
    height: 2px;
    background-color: grey;
    margin-bottom: 18px;
    opacity: 0.5;
  }
  
  .modal_contentBox {
    max-width: 850px;
    padding: 20px;
  }
  
  .modal_loading_contentBox {
    max-width: 850px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .sub_load_content {
    display: flex;
    flex-direction: row;
  }
  
  .img_file {
    width: 2.5rem;
    height: 3.5rem;
    margin-top: auto;
    margin-bottom: auto;
  
  }
  
  .status_img {
    width: 2.5rem;
    height: 2.5rem;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .file_name {
    max-width: 150px;
  }
  
  .img_txt_file {
    display: flex;
    flex-direction: column;
  }
  
  .loading_bar_pending {
    width: 30%;
    height: 10px;
    background-color: grey;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
  }
  
  .loading_bar_error {
    width: 30%;
    height: 10px;
    background-color: orange;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
  }
  
  .loading_bar_success {
    width: 30%;
    height: 10px;
    background-color: #0099DE;
    margin-top: auto;
    margin-bottom: auto;
    border-radius: 10px;
  }
  
  .text_area {
    font-family: Soleil-Regular;
    color: black;
    text-align: center;
    padding: 8px;
  }
  
  .text_file_area {
    font-family: Soleil-Regular;
    color: black;
    margin-bottom: 8px;
  }
  
  
  
  
  
  .linear_activity {
      overflow: hidden;
      width: 50%;
      height: 4px;
      background-color: #B3E5FC;
      margin: 20px auto;
  }
  .error_borders{
    outline: 2px solid #EA001E;
    outline-offset: -1.9px
    }

  .determinate {
      position: relative;
      max-width: 100%;
      height: 100%;
      -webkit-transition: width 500ms ease-out 1s;
      -moz-transition: width 500ms ease-out 1s;
      -o-transition: width 500ms ease-out 1s;
      transition: width 500ms ease-out 1s;
      background-color: #03A9F4;
  }
  