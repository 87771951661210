@import "../../variables";

@media screen and (max-width: 1350px) {
	.container {
		margin-top: 5%;
		max-width: 550px;
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.container {
		max-width: 100%;
	}
}
