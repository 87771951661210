.container {
	padding: 0 60px;
	--gap: 16px;
}
.divline_h2_container {
	margin-bottom: 22px;

	&__subTitle {
		font-size: 32px;
	}
	.titleFilter {
		font-size: 32px;
	}
}
h1 {
	padding-bottom: 20px;
}
.formGroup {
	display: flex;
	flex-direction: column;
	gap: 5px;
	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 5px;
	}
	input[type="text"],
	input[type="number"] {
		padding: 9px;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
	}
}

.filter_wrapper {
	--gap: 15px;
	background-color: #f8f8fc;
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: var(--gap);
	.col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		gap: var(--gap);
	}

	.subForm {
		font-size: 13px;
		&__row {
			display: grid;
			grid-gap: var(--gap) calc(var(--gap) * 3.5);
			grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
			&__threeCol {
				display: grid;
				grid-gap: var(--gap) calc(var(--gap) * 3.5);
				grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
			}
		}
		hr {
			margin: 50px 0;
		}
	}
	:global {
		.rw-listbox {
			height: 240px;
			font-size: 14px;
			max-height: 240px;
		}
		.rw-list-option {
			padding: 9px 12px;
			display: flex;
			align-items: center;
			font-size: 13px;
		}
	}
}
.btnFilter {
	display: flex;
	justify-content: right;
	padding-bottom: 60px;
	button {
		text-transform: initial;
		max-width: 320px;
	}
}
.seeMore {
	font-size: 14px;
	color: #06073a;
}

.containerResult {
	&__head,
	.result {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		align-items: center;
		&__title {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: #190038;
			padding: 14px 20px;
		}
		&__body {
			padding: 12px 16px;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			color: #06073a;
			span {
				&:not(:last-child) {
					&:after {
						content: ", ";
					}
				}
			}
			&__id {
				color: #5d0282;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
			}
			&__link {
				display: flex;
				align-items: center;
				a,
				a:active,
				a:hover {
					color: #06073a;
				}
			}
		}
	}
	.allResults {
		.result {
			border: 1px solid #ebedff;
			min-height: 40px;

			&:nth-child(even) {
				background: #f8f8fc;
			}
			&:not(:last-child) {
				border-bottom: 0;
			}
		}
	}
}

.arrowPagination {
	display: flex;
	justify-content: right;
	padding: 20px 0;
	button {
		background: none;
		border: none;
		margin: 0 25px;
		&.arrowLeft {
			img {
				transform: rotate(180deg);
			}
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	.totalPage {
		font-size: 14px;
		color: #3e0056;
		padding-right: 15px;
	}
}

// Responsive import
@import "./ListOfCV.responsive";
