@import "variables";
.subtitle_in_form {
  margin-top: 0px;
  margin-bottom: 20px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 0 4%;
  p {
    font-family: Soleil-Regular;
    font-size: 13px
  }
}
.wrapper_double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 0 4%;
}

.checkbox_container {
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 600;
    color: #5d0282;
  }
}

.submit_btn_to_right {
  display: flex;
  justify-content: end;
  padding-bottom: 32px;
  button {
    text-transform: lowercase;
    max-width: 320px;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .wrapper,
  .wrapper_double {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}
