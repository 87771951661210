@import "variables";

@media screen and (max-width: 1350px) {
	.form_container {
		flex-direction: column;
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		padding: 0 16px 60px;
	}

	.form_container {
		flex-direction: column;
		form {
			max-width: 100%;
		}
	}

	.inputs_container {
		width: 80%;
	}

	.title_container,
	.container {
		max-width: 100%;
	}

	.form_and_card_container {
		margin-top: 10px;
		max-width: 100%;
	}

	.blue_banner_container {
		max-width: 100%;
	}
}
