.main_container {
	padding: 0 60px 60px;
	overflow-x: auto;
}
.message {
    color: #EA001E;
    font-size: 12px;
    margin-top: 4px;
}
.title_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 550px;
	margin-bottom: 22px;
}

.form_container {
	display: flex;
	align-items: flex-start;
	// background-color: #F8F8FC;
	padding: 32px 0;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		max-width: 550px;

		flex: 0 0 550px;
		.consentement_text {
			font-family: Soleil-Leger;
			display: inline;
			vertical-align: middle;
			font-size: 0.6rem;
		}

		.star_for_consent {
			font-family: Soleil-Leger;
			color: red;
			font-size: 0.6rem;
			margin-top: 10%;
		}

		.sub_Title {
			width: 100%;
			margin-bottom: 45px;
			max-width: 300px;

			p {
				font-family: "Inseec-Medium";
				font-size: 18px;
				margin: 16px 0 0;
				inline-size: 223px;
				overflow: hidden;
			}

			.test {
				display: flex;
				align-items: self-end;
				width: 135%;
				// justify-content: space-between;
				&:after {
					content: "(optionnel)";
					font-family: Soleil;
					font-size: 14px;
					line-height: 16px;
					color: #818197;
					white-space: nowrap;
					margin-left: -50%;
				}
			}
			.test1 {
				display: flex;
				align-items: self-end;
				width: 100%;
				justify-content: space-between;
				// line-height: 100px;
				&:after {
					content: "(optionnel)";
					font-family: Soleil;
					font-size: 14px;
					line-height: 16px;
					color: #818197;
					white-space: nowrap;
				}
			}
		}
	}

	// p {
	//   display: block;
	//   margin-block-start: 1em;
	//   margin-block-end: 1em;
	//   margin-inline-start: 0px;
	//   margin-inline-end: 0px;
	//   display: flex;
	//   font-size: 18px;
	//   font-weight: 500;
	//   font-family: Inseec-Medium;
	//   width: 60%;
	//   margin-bottom: 24px;
	//   justify-content: space-between;
	//   display: flex;
	//   color: #190038;

	// }
}
.wrapper {
	display: flex;
	align-items: flex-start;
	.leftCol {
		max-width: 550px;
		flex: 0 0 550px;
	}
}
.step4_inputs_container {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin-bottom: 24px;
	font-family: Soleil-Regular;
	margin-left: 20%;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}

	.label_test_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		flex: 1;
	}
}

.inputs_container {
	display: flex;
	flex-direction: column;
	width: 60%;
	margin-bottom: 24px;
	font-family: Soleil-Regular;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		.optionalLabel {
			display: flex;
			align-items: self-end;
			width: 100%;
			justify-content: space-between;

			&:after {
				content: "(optionnel)";
				font-family: Soleil;
				font-size: 14px;
				line-height: 16px;
				color: #818197;
				white-space: nowrap;
			}
		}
	}

	.label_test_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		flex: 1;
	}
}

.checkbox_container {
	display: flex;
	font-family: Soleil-Leger;
	font-size: 13px;
	flex-direction: column;
	margin-bottom: 30px;
	width: 63%;
}

.span_for_label {
	font-family: Soleil-Leger;
	font-weight: 600;
	font-size: 10px;
	color: grey;
	margin-top: -10px;
	margin-bottom: 12px;
}

.add_exp_btn {
	width: 350px;
	height: 48px;
	margin-top: 32px;
	background: #9f9eb7;
	border-radius: 84px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Soleil-Regular;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	border-style: none;

	&:hover {
		cursor: pointer;
	}
}

@import "./CreateYourCV.responsive";
