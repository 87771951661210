@import "variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.offerWrapper {
		padding: 0 20px 20px;

		.actionWrapper {
			flex-direction: column;
			.lightBtn {
				margin-bottom: 20px;
			}
		}

		.container__Offer {
			padding: 30px;
			&__titleOffre {
				display: none;
			}
			.offreList {
				.offreListDetails {
					grid-template-columns: 1fr;
				}
			}
		}
		.toApplay {
			justify-content: center;
		}
	}
}
