@import "variables";
.wrapper {
	background: url("./bg-faq.png") no-repeat right top #f5f7fa;
	min-height: 100vh;
	.header {
		padding: 42px 60px 0px;
		.title {
			margin-bottom: 40px;
		}
		p {
			max-width: 750px;
		}
		.tab {
			max-width: 750px;
			display: flex;
			margin-top: 20px;
			button {
				width: 50%;
				cursor: pointer;
				font-family: "Inseec-Medium";
				font-size: 18px;
				line-height: 130%;
				text-align: center;
				padding: 24px 61px 21px;
				border: none;
				background: white;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				svg {
					margin-top: 15px;
				}
				&.stage {
					background: #5d0282;
					color: #fff;
					svg {
						transform: rotate(180deg);
						transition: 0.6s linear;
						path {
							fill: #fff;
						}
					}

					&.active {
						svg {
							transform: rotate(0);
						}
					}
				}
				&.alternance {
					svg {
						transform: rotate(180deg);
						transition: 0.6s linear;
					}

					&.active {
						svg {
							transform: rotate(0);
						}
					}
				}
			}
		}
	}
	.content {
		max-width: 750px;
		padding: 42px 60px 60px;
		box-sizing: content-box;
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.wrapper {
		background-image: none;
		.header {
			padding: 30px 15px;
			.tab {
				button {
					font-size: 12px;
					padding: 20px;
				}
			}
		}
		.content {
			width: 100%;
			padding: 0;
		}
	}
}
