.main_container {
	display: flex;
	flex-direction: column;
}

.first_message {
	font-size: 18px;
	margin-bottom: 5px;
}

.second_message {
	font-size: 15px;
}
