@import "../../../../../../variables";

.validate_exp_btn{
    max-width: 400px;
	width: 100%;
	height: 48px;
	margin-top: 32px;
	background: #5d0282;
	border-radius: 84px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Soleil-Regular;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
    align-Self:'center';
	text-transform: uppercase;
	color: #ffffff;
	border-style: none;
	&:hover {
		cursor: pointer;
	}
	@media screen and (max-width: $mobile-breakpoint) {
		max-width: 300px;
		font-size: 14px;
		padding: 0 16px;
        margin-left:10%
	}
}
.add_exp_btn_container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 16px;
    max-width: 320px;
    max-height: 48px;
    border-radius: 74px;
    align-self: center;
    .add_exp_btn {
        width: 320px;
        height: 48px;
        margin-bottom: 32px;
        background: #9F9EB7;
        margin: auto;
        border-radius: 84px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Inseec;
        font-size: 16px;
        line-height: 18px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        border-style: none;
    
        &:hover {
            cursor: pointer;
        }
    
        @media screen and (max-width: $mobile-breakpoint) {
            width: 300px;
            font-size: 14px;
            padding: 0 16px;
        }
    }
}
.add_proj_btn_container{
    --sds-c-button-text-color: #FFFFFF;
    --sds-c-button-text-size: 15px;
    align-self: center;
    font-family: Inseec;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 16px;
    max-width: 320px;
    max-height: 40px;
    top: 155px;
    background: #9F9EB7;
    border-radius: 74px;
    font-size: 12px;
.add_proj_btn{
    position: relative;
    display: inline-flex;
    align-items: center;
    padding-top: var(--slds-c-button-spacing-block-start, var(--sds-c-button-spacing-block-start, 0));
    padding-right: var(--slds-c-button-spacing-inline-end, var(--sds-c-button-spacing-inline-end, 0));
    padding-bottom: var(--slds-c-button-spacing-block-end, var(--sds-c-button-spacing-block-end, 0));
    padding-left: var(--slds-c-button-spacing-inline-start, var(--sds-c-button-spacing-inline-start, 0));
    background: none;
    background-color: var(--slds-c-button-color-background, var(--sds-c-button-color-background, transparent));
    background-clip: border-box;
    border-color: var(--slds-c-button-color-border, var(--sds-c-button-color-border, transparent));
    border-style: solid;
    border-width: var(--slds-c-button-sizing-border, var(--sds-c-button-sizing-border, var(--lwc-borderWidthThin,1px)));
    border-radius: var(--slds-c-button-radius-border, var(--sds-c-button-radius-border, var(--lwc-buttonBorderRadius,.25rem)));
    box-shadow: var(--slds-c-button-shadow, var(--sds-c-button-shadow));
    line-height: var(--slds-c-button-line-height, var(--sds-c-button-line-height, var(--lwc-lineHeightButton,1.875rem)));
    text-decoration: none;
    color: var(--slds-c-button-text-color, var(--sds-c-button-text-color, var(--lwc-brandAccessible,#0099DE)));
    white-space: normal;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@media screen and (max-width: $mobile-breakpoint) {
    width: 300px;
    font-size: 14px;
    padding: 0 16px;
    width: 101.633px;
    height: 34px;
   
    font-family: Inseec;
    display: inline-flex;
}
}