@import "variables";

.main_container {
	padding: 0 60px 60px;
}

.form_and_card_container {
	max-width: 550px;
	width: 100%;
}
.listHeader {
	list-style: disc;
	padding-left: 20px;
	line-height: 1.5;
}
.step_indicator {
	margin-left: 5%;
}
.title {
	padding: 20px;
}
.title_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 550px;
	margin-bottom: 22px;
}

.form_container {
	display: flex;
	gap: 0 60px;
	align-items: flex-start;

	form {
		background-color: #f8f8fc;
		max-width: 550px;
		padding: 32px 0;
	}
}
.container {
	max-width: 550px;
	width: 100%;
}
.box,
.submitButton {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.line {
	background-color: #9f9eb7;
	height: 2px;
	width: calc(100% - 45px);
	margin: 10px auto;
}
.inputs_container,
.checkbox_container {
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-bottom: 24px;
	font-family: Soleil-Regular;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}
	p {
		font-size: 13px;
		padding-bottom: 15px;
	}

	.label_text {
		&::after {
			content: "*";
			font-size: 12px;
			line-height: 16px;
			margin-left: 5px;
			color: rgb(221, 21, 21);
		}
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		.p_text {
			font-size: 12px;
			line-height: 1.1;
		}
	}
}

.checkbox_container {
	display: flex;
	flex-direction: column;
	align-items: start;
	font-family: Soleil-Leger;
	font-size: 13px;

	div {
		margin-left: 10px;
	}

	span {
		font-family: Soleil-Regular;
	}
}

.span_for_label {
	font-family: Soleil-Leger;
	font-weight: 600;
	font-size: 10px;
	color: grey;
	margin-top: -10px;
	margin-bottom: 12px;
}

.info_bubble {
	height: 12px;
	width: 12px;
	background-color: #f8f8fc;
	color: white;
	position: relative;
	padding-bottom: 15px;
	cursor: pointer;

	&:hover {
		&:before {
			content: attr(info-bubble-content);
			position: absolute;
			top: -50px;
			width: 100%;
			min-width: 250px;
			background-color: #16325c;
			padding: 8px;
			border-radius: 5px;
			font-size: 10px;
			font-family: Soleil-Leger;
		}
	}
}

.immediate_and_recurrent_container {
	display: flex;
	justify-content: space-between;
}

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		padding: 0;
	}
}
