.modal {
	/* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 100; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
	display: flex;
	justify-content: center;
	align-items: center;
}

/* Modal Content/Box */
.modal_content {
	background-color: #fefefe;
	border: 1px solid #888;
	border-radius: 5px;
	max-width: 640px;
	height: auto;
	width: 100%;
	position: relative;
	margin: 0 25px;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close_btn {
	background-color: #0099de;
	max-width: 280px;
	font-family: Soleil-Regular;
	height: 35px;
	border-radius: 0.25rem;
	text-align: center;
	padding: 0px 16px;
	cursor: pointer;
	color: #fefefe;
	font-size: 13px;
	border: none;
}
.footer_modal {
	display: flex;
	justify-content: end;
	padding: 0px 20px 20px 20px;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.modal_title {
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 20px;
	line-height: 130%;
	color: #06073a;
}

.modal_close_button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	right: 0;
	position: absolute;
	top: 19px;
	right: 10px;
}

.closeIcon_size {
	width: 1rem;
	height: 1rem;
}

.separator_top {
	max-width: 100%;
	height: 2px;
	background-color: grey;
	opacity: 0.5;
	margin-bottom: 10px;
}

.modal_contentBox {
	max-width: 850px;
	padding: 10px 20px 0px 20px;
}

.modal_loading_contentBox {
	max-width: 850px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.text_area {
	font-family: Soleil-Regular;
	color: black;
	font-size: 13px;
	padding: 10px 20px;
}

.text_file_area {
	font-family: Soleil-Regular;
	color: #222222;
	margin-bottom: 10px;
	font-size: 13px;
}

//@import "./Modal.responsive"
