@import "../../../variables";

.dropdown_container {
	height: calc(100vh - $mobile-nav-height);
	width: 100vw;
	margin-top: $mobile-nav-height;
	position: fixed;
	z-index: 999;
	background: #190038;
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-y: auto;

	a {
		color: white;
	}
}
