@import "variables";
.wrapper {
	background-color: #f5f7fa;
	.sectionHeader {
		padding: 42px 60px 68px;
		background: url(./assets/bg-consul-offre.png);
		background-repeat: no-repeat;
		background-position: right top;
		.title {
			padding-bottom: 32px;
		}
		.content {
			max-width: 500px;
		}
		.btnWrpper_follow {
			margin-left: 75px;
			max-width: 320px;
			width: 100%;
			height: 48px;
			margin-top: 32px;
			background: #5d0282;
			border-radius: 84px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: Soleil-Regular;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			border-style: none;
		}

		.btnWrpper_home {
			margin-left: 75px;
			max-width: 320px;
			width: 100%;
			height: 48px;
			margin-top: 32px;
			background: #ffffff;
			border-radius: 84px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: Soleil-Regular;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			color: #5d0282;;
			border-style: solid;
			border-color: #5d0282;
			border-width: 1px;
		}

		.btn_container {
			max-width: 100%;
			display: flex;
			flex-direction: row;
		}

		.whiteText {
			color: #ffffff;
		}
	}

	.third_container {
		padding: 0 60px 28px;
		.title_third_container {
			h2 {
				padding-bottom: 48px;
			}
		}
		.cards_container {
			display: flex;
			gap: 40px;
		}
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.wrapper {
		background-color: #f5f7fa;
		.sectionHeader {
			background: none;
			padding: 40px 16px 44px;

			.btnWrpper {
				margin-left: 0;
				button {
					width: 100%;
				}
			}
		}

		.third_container {
			padding: 0 30px 28px;

			.cards_container {
				flex-direction: column;
				gap: 20px;
			}
		}
	}
}


// @import "variables";
// .wrapper {
// 	background-color: #f5f7fa;
// 	.sectionHeader {
// 		padding: 42px 60px 68px;
// 		background: url(./assets/bg-consul-offre.png);
// 		background-repeat: no-repeat;
// 		background-position: right top;
// 		.title {
// 			padding-bottom: 32px;
// 		}
// 		.content {
// 			max-width: 500px;
// 		}
// 		.btnWrpper_follow {
// 			margin-left: 75px;
// 			// a {
// 				max-width: 320px;
// 				width: 100%;
// 				height: 48px;
// 				margin-top: 32px;
// 				background: #5d0282;
// 				border-radius: 84px;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				font-family: Soleil-Regular;
// 				font-size: 16px;
// 				line-height: 18px;
// 				text-align: center;
// 				//text-transform: uppercase;
// 				color: #ffffff;
// 				// border-style: none;
// 			}
// 		}

// 		.btnWrpper_home {
// 			margin-left: 75px;
			


// 			// a {
// 				max-width: 320px;
// 				width: 100%;
// 				height: 48px;
// 				margin-top: 32px;
// 				background: #FFFFFF;
// 				border-radius: 84px;
// 				display: flex;
// 				align-items: center;
// 				justify-content: center;
// 				font-family: Soleil-Regular;
// 				font-size: 16px;
// 				line-height: 18px;
// 				text-align: center;
// 				//text-transform: uppercase;
// 				color: #5d0282;
// 				// border-style: none;
// 				border-style: solid;
// 			border-color: #5d0282;
// 			// }
// 		}
// 	}

// 	.btn_container {
// 		max-width: 100%;
// 		display: flex;
// 		flex-direction: row;
// 	}

// 	.third_container {
// 		padding: 0 60px 28px;
// 		.title_third_container {
// 			h2 {
// 				padding-bottom: 48px;
// 			}
// 		}
// 		.cards_container {
// 			display: flex;
// 			gap: 40px;
// 		}
// 	}

// @media screen and (max-width: $mobile-breakpoint) {
// 	.wrapper {
// 		background-color: #f5f7fa;
// 		.sectionHeader {
// 			background: none;
// 			padding: 40px 16px 44px;

// 			.btnWrpper {
// 				margin-left: 0;
// 				button {
// 					width: 100%;
// 				}
// 			}
// 		}

// 		.third_container {
// 			padding: 0 30px 28px;

// 			.cards_container {
// 				flex-direction: column;
// 				gap: 20px;
// 			}
// 		}
// 	}
// }
