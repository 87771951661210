@import "../../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    .header_container {
        background-image: none;
        min-height: 30vh;
        padding: 40px 16px 44px 16px;
    }

    .header_content {
        padding-left: 0;
        
        h1 {
            padding-bottom: 20px;
            font-size: 36px;
            line-height: 120%;
        }

        p {
            padding-bottom: 28px;
        }
    }

    .btns_container {
        flex-direction: column;
        gap: 0;
        padding-left: 0;
    }

    .left_btn, .right_btn {
        width: 100%;
    }
}