// .header_container
.header_container {
    padding: 42px 30px 10px;
}

.header_content {
    padding-left: 30px;

    h1 {
        padding-bottom: 32px;
    }

    p {
        font-family: Soleil-Regular;
        font-size: 16px;
        color: #190038;
        padding-bottom: 40px;
        max-width: 492px;
    }
}

// .white_squares_container
.white_squares_container {
    display: flex;
    gap: 38px;
    padding-left: 30px;
    padding-bottom: 72px;
}

.white_square_card {
    background-color: #FFFFFF;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
    border-radius: 5px;
    padding: 8px 20px 14px;
    width: 210px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .cv_number_card {
        font-family: Inseec-Medium;
        font-weight: 500;
        font-size: 33px;
        color: #5D0282;
        line-height: normal;
    }

    .cv_text_card {
        font-size: 16px;
        color: #06073A;
    }

    .cv_link_card {
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        color: #3E0056;
        font-family: Inseec-Medium;
    }
}

// Responsive import
@import "./HomepageHeader.responsive";