@import "../../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    // .cv_list_container
    .cv_list_container {
        padding: 0;
        // background-color: #FFF;
    }

    // .divline_h2_container
    .divline_h2_container {
        padding: 0 30px;
    }

    // .cards_container
    .cards_container {
        margin-top: 32px;
        padding: 16px;
        flex-direction: column;
    }
}