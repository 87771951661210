.main_container {
	padding: 0 60px 60px;
	background-color: #fff;
}

.submit_btn_to_right {
	display: flex;
	justify-content: flex-end;
}

textarea {
	border: 1px solid #d4d4d4;
	border-radius: 4px;
	padding: 10px;
	resize: none;
	font-family: Soleil-Leger;
}

.title_container {
	font-family: Inseec-Medium;
	font-style: normal;
	font-weight: 500;
	font-size: 38px;
	line-height: 130%;
	color: #06073a;
	margin-bottom: 21px;
}

.main_title {
	box-sizing: border-box;
}

.offer_number_title {
	font-family: Inseec-Medium;
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 130%;
	color: #5d0282;
}

.export_close_offer_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.cloture_btn {
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	text-transform: uppercase;
	background-color: #5d0282;
	padding: 16px 50px;
	border: 1px solid #5d0282;
	box-sizing: border-box;
	border-radius: 74px;
	margin-bottom: 40px;
	cursor: pointer;
	text-decoration: none;
	display: flex;
}

.closure_btn_content {
	background: #5d0282;
	color: white;
	justify-content: center;
}

.export_btn {
	font-size: 16px;
	line-height: 16px;
	text-align: center;
	text-transform: uppercase;
	padding: 16px 50px;
	border: 1px solid #5d0282;
	box-sizing: border-box;
	border-radius: 74px;
	margin-bottom: 40px;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
}

.export_btn_content {
	color: #5d0282;
	padding-left: 10px;
}

.forms_main_container {
	background-color: #f8f8fc;
	padding: 24px 32px 32px;
}

.contract_type_and_study_lvl_container {
	display: flex;
	// justify-content: space-between;
	// width: 100%;
}

.forms_section_title {
	margin-bottom: 24px;
	font-family: Inseec-Medium;
	font-size: 18px;
	color: #06073a;
	text-transform: uppercase;
	display: block;
}

.section_separator {
	background: #9f9eb7;
	max-width: 100%;
	height: 1px;
	margin: 24px 0;
}

.inputs_container {
	margin-bottom: 24px;
	font-family: Soleil-Regular;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 50px;
	.dropdown_container {
		display: flex;
		flex-direction: column;
	}

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		& > div {
			max-height: 160px;
		}
	}
}

.date_and_place_container {
	display: grid;
	gap: 50px;
	grid-template-columns: repeat(2, 1fr);
	.sub_card_one {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.sub_card_two {
		display: flex;
		justify-content: space-between;
		gap: 20px;

		.telework_container {
			display: flex;
			justify-content: space-between;
			width: calc(50% - 20px);

			gap: 20px;
		}
	}

	.label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 0;
	}

	.checkbox_container {
		display: flex;
		font-family: Soleil-Leger;
		font-size: 13px;
		align-items: center;

		div {
			margin-left: 10px;
		}
	}

	.checkbox_row {
		display: flex;
		justify-content: space-between;
		&.jcontentStart {
			justify-content: start;
		}
	}

	.localisation_section {
		max-width: 45%;
		margin-bottom: 30px;
	}

	.dropdown_container {
		display: flex;
		flex-direction: column;
	}

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		width: calc(50% - 20px);
		input[type="text"],
		.codePostal {
			padding: 10px;
		}
	}

	.label_plus_input_telework_container {
		display: flex;
		flex-direction: column;
		width: 50%;
	}
}

.position_to_fufilled_container {
	display: flex;
	justify-content: space-between;
	max-width: 100%;
	flex-direction: column;

	.title_txt_section {
		display: flex;
		flex-direction: column;
	}

	.top_container {
		display: flex;
		gap: 50px;
	}
	.top_left_section {
		display: flex;
		flex-direction: column;
		max-width: calc(30% - 20px);
		flex: 0 0 30%;
	}
	.position_description_container {
		max-width: calc(70% - 20px);
		flex: 0 0 70%;
	}
	.dropdown_container {
		display: flex;
		flex-direction: row;
	}

	.label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.label_plus_input_language_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;

		height: 200px;
		max-width: 30%;
		width: 100%;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}

	.rqth_info {
		font-family: "Soleil-Regular"; /*Can be any text*/
		font-size: 10px;
		color: grey;
	}

	.position_description_container {
		.description_input {
			height: 130px;
		}
	}

	.middle_container {
		display: flex;
		gap: 25px;

		.middle_right_container {
			display: flex;
			flex-direction: column;
			width: 80%;
			.section {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				.label_plus_input_container {
					width: calc(100% / 3 - 20px);
				}
			}
		}
	}

	.bottom_container {
		display: flex;
		flex-direction: row;
		margin-top: 2%;
		justify-content: space-between;
	}

	.info_bubble {
		height: 12px;
		width: 12px;
		background-color: #135f90;
		color: white;
		cursor: pointer;
		border-radius: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		align-items: center;
		font-size: 9px;
		margin-bottom: 5px;
		&:hover {
			&:before {
				content: "Offre ouverte aux profils bénéficiant d'une reconnaissance de la qualité de travailleur handicapé";
				position: absolute;
				top: -50px;
				width: 100%;
				min-width: 250px;
				// height: 100px;
				background-color: #135f90;
				padding: 8px;
				border-radius: 5px;
				font-size: 10px;
				font-family: Soleil-Leger;
				z-index: 99;
			}
		}
	}
}

@import "./OfferDetails.responsive";
