@import "../../../../variables";

@media screen and (max-width: 1350px) {
	.inputs_container {
		grid-template-columns: repeat(2, 1fr);
	}
	.date_and_place_container {
		grid-template-columns: 1fr;
	}
	.position_to_fufilled_container {
		.top_container {
			flex-direction: column;
			gap: 20px;
		}
		.top_left_section,
		.position_description_container {
			max-width: 100%;
			flex: 0 0 1;
		}
	}
	.position_to_fufilled_container {
		.middle_container {
			flex-direction: column;
			.middle_right_container {
				width: 100%;
				.section {
					flex-wrap: wrap;
					.label_plus_input_container {
						width: calc(100% / 2 - 20px);
					}
				}
			}
		}
		.middle_container {
			display: flex;
			gap: 25px;
		}
		.label_plus_input_language_container {
			max-width: 100%;
			height: auto;
			margin-bottom: 0;
		}
	}
}
// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.main_container,
	.forms_main_container {
		padding: 0 20px;
	}
	.inputs_container {
		grid-template-columns: 1fr;
		gap: 20px;
	}
	.date_and_place_container {
		flex-wrap: wrap;
		gap: 20px;

		.sub_card_two {
			margin-bottom: 20px;
			flex-direction: column;
			.telework_container {
				width: 100%;
				flex: 1;
				flex-wrap: wrap;
			}
		}
		.label_plus_input_container {
			width: 100%;
			margin-bottom: 0;
		}
		.label_plus_input_telework_container {
			width: 100%;
			margin-bottom: 20px;
		}
	}

	.position_to_fufilled_container {
		.middle_container {
			.middle_right_container {
				.section {
					.label_plus_input_container {
						width: 100%;
					}
				}
			}
		}
	}
}
