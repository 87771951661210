@import "../../variables";

.disable_btn {
    width: 17rem;
    height: 58px;
    background: #3E0056;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Soleil-Regular;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border-style: none;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 300px;
        font-size: 14px;
        padding: 0 16px;
    }
}