@import "variables";

.row_container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 4%;
  .dropdown_list {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  p {
    font-family: Soleil-Regular;
    font-size: 13px;
  }
}

.input_field {
  margin-bottom: 0px;
}

.row_container_double {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4%;
}

.sub_subtitle {
  font-size: 14px;
  margin-top: 0px;
  margin-bottom: 5px;
}

.form_container {
  display: flex;
  flex-direction: column;
}

.subtitle {
  margin-bottom: 5px;
}

.submit_btn_to_right {
  display: flex;
  justify-content: end;
  padding-bottom: 32px;
  button {
    text-transform: lowercase;
    max-width: 320px;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .row_container {
    grid-template-columns: 1fr;
  }

  .row_container_double {
    grid-template-columns: 1fr;
  }

  .input_field {
    margin-top: 10px;
    margin-bottom: 15px;
  }
}
