@import "../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    .header_container {
        background-image: none;
        min-height: 30vh;
        padding: 40px 16px 44px 0;
    }

    .header_content {
        h1 {
            padding-bottom: 20px;
            font-size: 36px;
            line-height: 120%;
        }

        p {
            padding-bottom: 28px;
        }
    }
}