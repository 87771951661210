@import "variables";

@media screen and (max-width: 1350px) {
	.offerWrapper {
		.actionWrapper {
			flex-direction: column;
			button {
				margin-bottom: 15px;
			}
		}
		.container__Offer {
			.offreList {
				.offreListDetails {
					&__option {
						width: 50%;
					}
				}
			}
		}
	}
}
// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.offerWrapper {
		padding: 0 20px 20px;

		.actionWrapper {
			flex-direction: column;
			.lightBtn {
				margin-bottom: 20px;
			}
		}

		.container__Offer {
			padding: 30px;
			&__titleOffre {
				font-size: 30px;
			}
			.offreList {
				.offreListDetails {
					flex-direction: column;
					&__option {
						width: 100%;
					}
				}

				.skills {
					.containerSkills {
						.multiCol {
							width: 100%;
						}
					}
				}
			}
			.experience {
				.offreListDetails {
					&__option {
						width: 100%;
					}
				}
			}
		}
		.toApplay {
			justify-content: center;
		}
	}
}
