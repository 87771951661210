@import "variables";

.label_plus_input_container {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
}

.custom_input_container {
	position: relative;
	padding: 2px;
	border: 1px dashed #d4d4d4;
	border-radius: 4px;
	max-width: 330px;
	.input_label {
		display: flex;
		align-items: center;
		margin-bottom: 0;
		.uploadFile {
			display: inline-flex;
			align-items: center;
			font-family: "Soleil-Regular";
			padding: 3px 8px 0 16px;
			border: 1px solid #d4d4d4;
			border-radius: 4px;
			line-height: 2;
			color: #0099de;
			// inline-size: 160px;
			// overflow-wrap: break-word;
			text-align: center;
			min-height: 62px;
			font-weight: 200;
			max-width: 170px;
			width: 100%;
			&:hover {
				background-color: rgba(25, 124, 190, 0.05);
				color: #135f90;
				svg {
					path {
						fill: #135f90;
					}
				}
			}
			svg {
				path {
					fill: #0099de;
				}
			}
		}
		.dragDrop {
			font-size: 13px;
			line-height: 18.5px;
			margin-left: 8px;
			margin-right: 12px;
			display: block;
			font-weight: 200;
			color: #222222;
			inline-size: 138px;
			overflow-wrap: break-word;
		}
	}
}

.disabled_div_upload {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgb(233, 236, 239, 0.9);
}

.filename_and_delete_container {
	.delete_btn {
		margin-top: 5px;
		margin-left: 12px;
		color: #190038;

		&:hover {
			cursor: pointer;
		}

		img {
			width: 12px;
			height: 12px;
		}
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.custom_input_container {
		.input_label {
			.uploadFile {
				min-height: auto;
				max-width: 100%;
				padding: 0 8px;
				svg {
					margin-right: 6px;
				}
			}
			.dragDrop {
				display: none;
			}
		}
	}
}
