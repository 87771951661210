@import "../../variables";

.continuemodify_btn {
    width: 17rem;
    height: 58px;
    color: #3E0056;
    border-radius: 84px;
    border: 1px solid #3E0056;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Soleil-Regular;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    background: #FFFFFF;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 300px;
        font-size: 14px;
        padding: 0 16px;
    }
}

.continuemodify_title {
    display: flex;
    align-items: flex-start;
    padding: 1rem;
}