@import "../../../../../../../../variables";

@media screen and (max-width: $mobile-breakpoint) {
  .row_inputs_container {
    flex-direction: column;

    .label_plus_input_container {
      width: 100%;
      max-width: 100%;
    }

    .input_container > div > div {
      width: 100%;
    }
  }

  .submit_btn_to_right {
    display: flex;
    justify-content: center;
  }
}
