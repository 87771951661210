@import "../../../variables";

@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		padding: 0 16px 60px;
	}

	.inputs_container {
		width: 100%;
	}
	.step4_inputs_container {
		width: 10%;
	}
	.sub_title {
		width: 100%;
	}

	.form_container {
		flex-direction: column;
		form {
			max-width: 100%;
			flex: 0 0 100%;
			width: 100%;
		}
	}
	.wrapper {
		flex-direction: column;

		.leftCol {
			max-width: 100%;
			flex: 0 0 100%;
			width: 100%;
		}
	}
}
