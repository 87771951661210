@import "variables";
.subtitle_in_form{
	margin-top: 0px;
	margin-bottom: 20px
}
.functionInput{
		width: 221%;
	}
.BoxValided {
		display: flex;
		align-items: flex-start;
		padding-bottom: 24px;
		label {
			font-size: 13px;
			line-height: 1.6;
			color: #5a5a5a;
		}
	}
.row_grid{
		display: grid;
		grid-template-columns: repeat(4,1fr);
		gap : 0 4%;
		p {
			font-family: Soleil-Regular;
			font-size: 13px
		}
	}
.row_grid_double{
		display: grid;
		grid-template-columns: repeat(2,1fr);
		gap : 0 4%;
	}
.submit_btn_to_right {
	display: flex;
	justify-content: end;
	padding-bottom: 32px;
	button {
		text-transform: lowercase;
		max-width: 320px;
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.functionInput{
	max-width: 100%;
}
}
@media screen and (max-width: $mobile-breakpoint) {
	.row_grid , .row_grid_double{
		display: grid;
		grid-template-columns: 1fr

	}

}
