.container {
	background: #ffffff;
	box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
	max-width: 425px;
	width: 100%;
	padding: 14px 28px;
	margin-top: 135px;
	flex: 0 0 425px;
}

.valueContainer {
	flex-direction: column;
	display: flex;
}

.dataContainer {
	box-sizing: border-box;
	display: block;
	font-family: Soleil-Regular;
	font-size: 0.8125rem;
}

.title {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	color: #190038;
	margin-bottom: 22px;
}

.libele {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #5d0282;
	margin-bottom: 6px;
	padding-bottom: 6px;
	border-bottom: 1px solid #9f9eb7;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: rgb(159, 158, 183);
}

.value {
	font-family: Soleil-Leger;
	font-size: 14px;
	line-height: 16px;
	color: #190038;
	margin-bottom: 32px;
}

.divMargin {
	margin-bottom: 32px;
}

@import "./ResumeCard.responsive";
