.step_container {
    width: 48%;
    padding: 22px 30px;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 30px;

    p {
        font-size: 14px;
        color: #3E0056;
    }

    .title_container {
        display: flex;
        align-items: flex-start;
        margin-bottom: 10px;

        div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #5D0282;
            color: white;
            font-weight: 500;
            font-size: 18px;
            margin-right: 14px;
            padding: 10px;
        }
    }
}

// Responsive import
@import "./ItemStep.responsive";