.loader {
    border: 4px solid #f3f3f3;
    background-color: white;
    border-top: 4px solid #5d0282;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 