@import "../../../variables";
@media screen and (max-width: 1350px) {
	.offer_container {
		grid-template-columns: repeat(2, 1fr);
	}
}
// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		padding: 0;
	}

	.title_container {
		padding: 0 30px;
	}

	.offer_container {
		grid-template-columns: 1fr;
	}
}
