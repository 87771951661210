  .modalContainer {
      width: 100%;
      height: 100%;
      transition-duration: var(--lwc-durationSlowly, 0.4s);
      background: var(--slds-c-backdrop-color-background, var(--sds-c-backdrop-color-background, var(--lwc-colorBackgroundTempModalTint, rgba(8, 7, 7, 0.6))));
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0px;
      left: 0;
      z-index: var(--lwc-zIndexModal, 9000);
      overflow-y: auto;
      .modalWrapper {
          max-width: 800px !important;
          width: 800px;
          height: auto;
          margin: 50px auto;
          padding: 48px 0;
          position: relative;
          background: #FFFFFF;
          box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
          transform: translate(0, 0);
          transition: transform var(--lwc-durationQuickly, 0.1s) linear, opacity var(--lwc-durationQuickly, 0.1s) linear;
          display: flex;
          align-items: center;
          justify-content: center;
          form {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          .label_plus_input_container {
              display: flex;
              flex-direction: column;
              margin-bottom: 24px;
          }

          label {
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #5D0282;
              margin-bottom: 12px;
          }
        }
          .titleContainer {
              max-width: 604px;
              // border-width: 1px;

              // border-color: #06073A;
              // border-style: solid;
              margin: auto;
              font-family: Inseec;
              font-weight: bold;
              font-size: 2.5vw;
              // font-size: 32px;
              line-height: 130%;
              text-align: center;
              color: #06073A;
              display: block;
              margin-block-start: 1em;
              margin-block-end: 1em;
              margin-inline-start: 0px;
              margin-inline-end: 0px;

              .title {
                  word-wrap: break-word;
                  line-height: var(--lwc-lineHeightText, 1.5);
                  hyphens: manual;
                  text-size-adjust: 100%;
              }

              @media screen and (max-width:768px) {
                  .title {

                      text-size-adjust: 100%;
                  }

                  font-size: 3.7vw;
                  //   width: 65%;

              }
          }

          @media screen and (max-width:768px) {

              width: 90%;
          }
      }
      .submit_button_container{
        --sds-c-button-text-color: #FFFFFF;
        display: flex;
        justify-content: center;
        width: 285px;
        height: 48px;
        background: #5D0282;
        border-radius: 84px;
        margin: 30px auto 0;
        .submit_button{
            position: relative;
            display: inline-flex;
            align-items: center;
            padding-top: var(--slds-c-button-spacing-block-start, var(--sds-c-button-spacing-block-start, 0));
            padding-right: var(--slds-c-button-spacing-inline-end, var(--sds-c-button-spacing-inline-end, 0));
            padding-bottom: var(--slds-c-button-spacing-block-end, var(--sds-c-button-spacing-block-end, 0));
            padding-left: var(--slds-c-button-spacing-inline-start, var(--sds-c-button-spacing-inline-start, 0));
            background: none;
            background-color: var(--slds-c-button-color-background, var(--sds-c-button-color-background, transparent));
            background-clip: border-box;
            border-color: var(--slds-c-button-color-border, var(--sds-c-button-color-border, transparent));
            border-style: solid;
            border-width: var(--slds-c-button-sizing-border, var(--sds-c-button-sizing-border, var(--lwc-borderWidthThin,1px)));
            border-radius: var(--slds-c-button-radius-border, var(--sds-c-button-radius-border, var(--lwc-buttonBorderRadius,.25rem)));
            box-shadow: var(--slds-c-button-shadow, var(--sds-c-button-shadow));
            line-height: var(--slds-c-button-line-height, var(--sds-c-button-line-height, var(--lwc-lineHeightButton,1.875rem)));
            text-decoration: none;
            color: var(--slds-c-button-text-color, var(--sds-c-button-text-color, var(--lwc-brandAccessible,#0099DE)));
            white-space: normal;
            user-select: none;
        }
      }

      .buttonContainer {
          --sds-c-button-text-color: #FFFFFF;
          display: flex;
          justify-content: center;
          width: 285px;
          height: 48px;
          background: #5D0282;
          border-radius: 84px;
          margin: 30px auto 0;
      }

      .modifyButton {
          position: relative;
          display: inline-flex;
          align-items: center;
          padding-top: var(--slds-c-button-spacing-block-start, var(--sds-c-button-spacing-block-start, 0));
          padding-right: var(--slds-c-button-spacing-inline-end, var(--sds-c-button-spacing-inline-end, 0));
          padding-bottom: var(--slds-c-button-spacing-block-end, var(--sds-c-button-spacing-block-end, 0));
          padding-left: var(--slds-c-button-spacing-inline-start, var(--sds-c-button-spacing-inline-start, 0));
          background: none;
          background-color: var(--slds-c-button-color-background, var(--sds-c-button-color-background, transparent));
          background-clip: border-box;
          border-color: var(--slds-c-button-color-border, var(--sds-c-button-color-border, transparent));
          border-style: solid;
          border-width: var(--slds-c-button-sizing-border, var(--sds-c-button-sizing-border, var(--lwc-borderWidthThin, 1px)));
          border-radius: var(--slds-c-button-radius-border, var(--sds-c-button-radius-border, var(--lwc-buttonBorderRadius, .25rem)));
          box-shadow: var(--slds-c-button-shadow, var(--sds-c-button-shadow));
          line-height: var(--slds-c-button-line-height, var(--sds-c-button-line-height, var(--lwc-lineHeightButton, 1.875rem)));
          text-decoration: none;
          color: var(--slds-c-button-text-color, var(--sds-c-button-text-color, var(--lwc-brandAccessible, #0099DE)));
          white-space: normal;
          user-select: none;
          cursor: pointer;
      }

      .closeButton {
          cursor: pointer;
          position: absolute;
          top: 20px;
          right: 20px;
          width: 32px;
          height: 32px;
          padding: 0;
          z-index: 10;
      }

      .formContainer {
          width: 550px;
          margin: auto;
          margin-top: 30px;
          background: #F8F8FC;
          padding: 20px;
        width: 80%;
          .etab {
              width: 100%;
              font-family: Soleil;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 20px;
              display: flex;
              align-items: center;
              color: #190038;
              margin-bottom: 8px;
          }

          .institution {
              width: 100%;
              height: 17px;
              font-family: Soleil;
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #5D0282;
          }

          .diploma {
              width: 100%;
              height: 17px;
              font-family: Soleil;
              font-weight: 600;
              font-size: 14px;
              line-height: 18px;
              color: #5D0282;
              margin-bottom: 8px;
          }

          .date {
              width: 100%;
              height: 17px;
              font-family: Soleil;
              font-size: 14px;
              line-height: 16px;
              color: #3E0056;
          }

          .country {

              width: 100%;
              height: 17px;
              font-family: Soleil;
              font-size: 14px;
              line-height: 16px;
              color: #3E0056;
              margin-bottom: 24px;

          }

          .description {
              width: 100%;
              height: 17px;
              font-family: Soleil;
              font-size: 14px;
              line-height: 18px;
              display: flex;
              align-items: center;
              color: #5D0282;
              margin-bottom: 4px;
          }

          .dataDescription {
              width: 100%;
              font-family: Soleil;
              font-size: 14px;
              line-height: 16px;
              color: #190038;
          }

          @media screen and (max-width:768px) {

              width: 90%;
          }

      }



  }