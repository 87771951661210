@import "../../../variables";

// .discover_container
.discover_container {
    display: flex;
    margin: 0 30px 90px 30px;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
    border-radius: 5px;
}

.discover_trapeze_img {
    width: 385px;
}

.discover_mobile_img {
    display: none;
}

.discover_content_container {
    padding: 38px 0 46px;
}

.discover_content_title {
    font-size: 32px;
    padding-bottom: 15px;
    max-width: 700px;
}

.discover_content_desc {
    font-size: 14px;
    line-height: 16px;
    max-width: 660px;
    color: #3E0056;
}

// .second_container
.second_container {
    padding: 52px 30px 28px;
    background-color: #F8F8FC;
}

.title_second_container {
    padding-left: 30px;
    margin-bottom: 50px;
}

.steps_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

// .third_container
.third_container {
    display: flex;
    flex-direction: column;
    padding: 52px 30px 28px;
}

.title_third_container {
    padding-left: 30px;
    margin-bottom: 40px;
}

// .cards_container
.cards_container {
    width: 100%;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    text-decoration: none;
}

// Responsive import
@import "./Homepage.responsive";