.card_container {
	width: 30%;
	padding: 16px;
	background-color: #fff;
	box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
	border-radius: 10px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;

	.status {
		font-family: Soleil-Leger;
		font-size: 14px;
		line-height: 16px;
		color: #190038;
		height: 16px;
		margin-bottom: 8px;
	}

	.cv_id {
		font-family: Soleil-Regular;
		font-weight: 600;
		font-size: 16px;
		color: #190038;
		height: 24px;
	}

	.name {
		font-family: Soleil-Regular;
		font-weight: 500;
		font-size: 16px;
		line-height: 20px;
		color: #5d0282;
		margin-bottom: 8px;
	}

	.duo_container {
		display: flex;
		width: 100%;

		.first_item {
			width: 45%;
			font-family: "Inseec-Medium";
			font-weight: 500;
			font-size: 11px;
			line-height: 16px;
		}

		.second_item {
			margin-left: 10px;
			width: 50%;
			font-family: Soleil-Regular;

			font-size: 14px;
			line-height: 16px;
			color: #190038;
		}
	}

	.edit_link {
		margin-top: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 45px;
		color: #190038;

		div {
			text-transform: uppercase;
			display: flex;
			align-items: center;
			font-family: "Inseec-Medium"; /*Can be any text*/

			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
			color: #190038;
			text-decoration: none;
			background-repeat: no-repeat;
			background-position: top 50% right 30%;
			cursor: pointer;
		}
	}
}

// Responsive import
@import "./Card.responsive";
