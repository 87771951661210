// Imports
@import "variables";

// Global application structure for Desktop
.navbar_and_content_container {
    margin-left: $sidenav-width;
    z-index: 2;
}


// Responsive SCSS import
@import "src/App.responsive";