@import "../../../variables"; // Mobile

@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		margin: 0;
		justify-content: start;

		.btnCnx {
			justify-content: start;
			margin: 0;
			padding: 0 12px 0 0;
			font-size: 18px;
		}
	}

	.signout_dropdown {
		width: 60%;
		top: 150%;
		z-index: 100;
	}
}
