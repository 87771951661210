.main_container {
	padding: 0 60px 60px;
}

.status {
	font-family: Soleil-Regular;
	font-size: 0.8125rem;
}

.title_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 550px;
	margin-bottom: 22px;
}

.offer_container {
	background-color: #f8f8fc;
	padding: 30px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}

.card {
	min-width: 30%;
	padding: 16px;
	background-color: #fff;
	box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
	border-radius: 10px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
}

.offer_name {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 16px;
	color: #190038;
	margin-top: 7px;
}

.margin_info {
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: #5d0282;
	margin-bottom: 10px;
	height: 20px;
}

.card_container {
	display: flex;
	width: 100%;
}

.text_container {
	width: 100%;
}

.text {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
	text-transform: uppercase;
	color: #190038;
	padding-top: 5%;
	width: 100%;
}

.data_style {
	word-break: break-word;
	width: 100%;
	font-family: Soleil-Regular;
	font-size: 14px;
	line-height: 16px;
	color: #190038;
	padding-top: 3%;
}

.margin_data {
	box-sizing: border-box;
}

.btn_container {
	text-transform: uppercase;
	display: flex;
	align-items: center;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	text-decoration: none;
	cursor: pointer;
}

.btn_style {
	text-transform: uppercase;
	display: flex;
	align-items: center;
	font-family: Inseec-Medium;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #190038;
	text-decoration: none;
}

.dataDisplay {
	display: flex;
	flex-direction: row;
}
.dataName {
	display: flex;
	flex-direction: column;
	width: 35%;
}

.dataValue {
	display: flex;
	flex-direction: column;
	margin-left: 10%;
}

@import "./_FollowOffers.responsive";
