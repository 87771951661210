.wrapper {
	background-color: #f5f7fa;
}

.main_container {
	padding: 0 60px 60px;
}

.form_and_card_container {
	max-width: 550px;
	width: 100%;
}

.step_indicator {
	margin-left: 5%;
}

.title_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 550px;
	margin-bottom: 22px;
}

.form_container {
	display: flex;
	gap: 0 60px;
	align-items: flex-start;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #ffffff;
		max-width: 550px;
		padding: 32px 0;
	}
}

.container {
	max-width: 550px;
	width: 100%;
}

.inputs_container {
	display: flex;
	flex-direction: column;
	width: 70%;
	font-family: Soleil-Regular;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.special_label {
		color: 'red'
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
		position: relative;

		.special_label {
			color: grey;
			font-size: 11px;
			font-weight: bolder;
		}

		input {
			padding: 6px 10px;
		}
	}

	.Dropdown_label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}
}

.checkbox_container {
	display: flex;
	font-family: Soleil-Leger;
	font-size: 13px;
	align-items: center;

	div {
		margin-left: 10px;
	}
}

.span_for_label {
	font-family: Soleil-Leger;
	font-weight: 600;
	font-size: 10px;
	color: grey;
	margin-top: -10px;
	margin-bottom: 12px;
}

.info_bubble {
	height: 14px;
	width: 14px;
	background-color: #f8f8fc;
	color: white;
	position: relative;
	padding-top: 2px;
	cursor: pointer;

	&:hover {
		&:before {
			content: attr(info-bubble-content);
			position: absolute;
			bottom: +20px;
			width: 100%;
			min-width: 250px;
			background-color: #16325c;
			padding: 8px;
			border-radius: 5px;
			font-size: 12px;
			line-height: 1.5;
			font-family: Soleil-Leger;
		}
	}
}

.immediate_and_recurrent_container {
	display: flex;
	justify-content: space-between;
}

.checkbox_svg_text_container {
	display: flex;
}

.checkbox_and_svg_container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.text {
	font-family: Soleil-Leger;
	font-size: 13px;
	line-height: 1.5;
	max-width: 300px;
	margin-left: 5px;
}

.btns_container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
}

.modal_container {
	position: fixed;
	width: 100vw;
	height: 100vh;
	background-color: rgb(0, 0, 0, 0.5);
	z-index: 1000;
	top: 0;
	left: 0;
}

.upload_file_container {
	width: 50%;
	background-color: rgb(255, 255, 255);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 0.25rem;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.title_modal_container {
	height: 59px;
	border-bottom: 3px solid #c1c0c0;
	color: #222222;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	font-weight: bold;
}

.file_modal_container {
	height: 63px;
}

.btn_modal_container {
	height: 59px;
	border-top: 3px solid #c1c0c0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;

	span {
		font-size: 12px;
		font-family: Soleil-Leger;
	}

	button {
		background-color: #0099de;
		border-color: #0099de;
		color: #fff;
		border-radius: 5px;
		padding: 5px 10px;
		font-size: 13px;
		font-family: Soleil-Leger;
		outline: none;
	}
}

.blue_banner_container {
	max-width: 550px;
	background-color: #467dd7;
	padding: 12px 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 20px;

	div {
		position: relative;
		width: 100%;
		height: 30px;

		svg {
			position: absolute;
			left: 55px;
			width: 30px;
			height: 30px;
		}
	}

	p {
		max-width: 80%;
		font-size: 13px;
		color: #ffffff;
		line-height: 1.5;
	}

}

.outputCard {
	display: flex;
	flex-direction: row;
}

.outputKeyLabel {
	align-items: flex-start;
	flex-direction: column;
	display: flex;
}

.output_container {
	background-color: #fff;
	max-height: 200px;
	top: 85px;
	overflow-x: auto;
	z-index: 9;
	position: absolute;
	width: 100%;
	cursor: pointer;
	
}

.icon_plus_message_container {
	display: flex;
}

.message_container {
	display: flex;
	font-size: 15px;
	line-height: 1.5;
	margin: 5px 10px 0px;

	span {
		color: #4d4d4d;
	}
}

.inside_search_icon {
	margin: 5px 10px 0px;
	// margin-left: 10px;
}

.output_plus_image_Container {
	display: flex;
	padding: 8px;
	font-size: 13px;
	line-height: 1.5;
	cursor: pointer;
	li {
	  color: #4d4d4d;
	  padding-left: 10px;
	}
	&:hover{
	  background-color: #f0f0f0;
	}
  }

.search_container {
	position: relative;
}

.search_icon {
	position: absolute;
	top: 50%;
	right: 10px;
	transform: translateY(-50%);
	color: #999;
	cursor: pointer;
}

input[type="text"] {
	padding-right: 30px;
}

// .test  span svg {
// 	display: none
// }
@import "./Step6.responsive";