.headerContent {
	max-width: 500px;
	width: 100%;
	p {
		padding-bottom: 0;
	}
	ul {
		font-family: Soleil-Regular;
		font-size: 16px;
		color: #190038;
		line-height: 24px;
		list-style-type: disc;
		padding-left: 24px;
	}
}
