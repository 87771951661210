@import "variables";
.wrapper {
	padding: 0 0 50px 50px;
	section {
		.title {
			padding: 50px 40px 30px;
		}
		span {
			color: #696969;
		}
		ol {
			list-style: auto;
			padding-left: 15px;
			padding-top: 15px;
			li {
				line-height: 1.7;
			}
		}
		&.sectionBg {
			background: url("./bg-faq.png") no-repeat;
			background-size: contain;
			background-position: right center;
		}
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.wrapper {
		padding: 20px;
		section {
			.title {
				padding: 20px 0;
			}
		}
	}
}
