@import "../../variables";

.main_container {
	img {
		&:hover {
			cursor: pointer;
		}
	}
}

.arrow_container {
	cursor: pointer;
}

.signout_dropdown {
	position: absolute;
	color: black;
	background-color: #ffffff;
	border-radius: 2px;
	padding: 15px;
	top: 80%;
	right: 30px;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	font-size: 13px;

	&:hover {
		cursor: pointer;
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.main_container {
		margin: 0;
		justify-content: start;
		position: relative;
	}

	.signout_dropdown {
		width: 60%;
		left: 0;
		top: 30px;
		font-size: 12px;
		height: 38px;
	}
}
