.hideDesktop {
	display: none;
}

.containerResult {
	padding: 0 60px;
	&__head,
	.result {
		display: grid;
		grid-template-columns: repeat(6, 1fr);
		align-items: center;
		&__title {
			text-transform: uppercase;
			font-size: 14px;
			line-height: 16px;
			text-align: center;
			color: #190038;
			padding: 14px 0;
			font-family: "Inseec-Medium"; /*Can be any text*/
		}
		&__body {
			padding: 12px 16px;
			text-align: center;
			font-size: 14px;
			line-height: 16px;
			color: #06073a;

			&__id {
				color: #5d0282;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 14px;
				font-family: "Soleil-Leger"; /*Can be any text*/
				span {
					&:not(:last-child) {
						padding-right: 5px;
					}
				}
			}
			&__link {
				.btn_seeMore {
					display: flex;
					align-items: center;
					justify-content: space-around;
					font-size: 14px;
					font-family: "Soleil-Leger";
					padding: 0 35px;
					white-space: nowrap;
					a,
					a:active,
					a:hover {
						color: #06073a;
					}
				}
			}
		}
	}
	.allResults {
		.result {
			border: 1px solid #ebedff;
			min-height: 40px;
			padding: 20px;

			&:nth-child(even) {
				background: #f8f8fc;
			}
			&:not(:last-child) {
				border-bottom: 0;
			}
		}
	}
}

.arrowPagination {
	display: flex;
	justify-content: right;
	padding: 20px 60px;
	button {
		background: none;
		border: none;
		margin: 0 25px;
		&.arrowLeft {
			img {
				transform: rotate(180deg);
			}
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	.totalPage {
		font-size: 14px;
		color: #3e0056;
		padding-right: 15px;
	}
}

// Responsive import
@import "./LearningBooklets.responsive";
