@import "../../variables";

.previous_btn_from {
	max-width: 400px;
	width: 100%;
	height: 48px;
	margin-top: 32px;
	background: #ffffff;
	border: 1px solid #5d0282;
	border-radius: 84px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Soleil-Regular;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #5d0282;

	&:hover {
		cursor: pointer;
	}

	@media screen and (max-width: $mobile-breakpoint) {
		max-width: 300px;
		font-size: 14px;
		padding: 0 16px;
	}
}
