@import "variables";
.wrapper {
  background: url("./bg-faq.png") no-repeat right top #f5f7fa;
  .header {
    padding: 42px 60px 60px;
    .title {
      margin-bottom: 40px;
    }
    p {
      max-width: 750px;
    }
  }
  .content {
    max-width: 750px;
    padding: 42px 60px 60px;
    box-sizing: content-box;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .wrapper {
    .header {
      padding: 30px 15px;
    }
    .content {
      padding: 42px 0 60px;
    }
  }
}
