.header_container {
    padding: 42px 30px 90px;
}

.header_content {
    padding-left: 30px;

    h1 {
        padding-bottom: 32px;
    }

    p {
        font-family: Soleil-Regular;
        font-size: 16px;
        color: #190038;
        padding-bottom: 40px;
        max-width: 492px;
    }
}

.btns_container {
    display: flex;
    gap: 20px;
    padding-left: 30px;
}

.left_btn {
    height: 48px;
    width: auto;
    margin-top: 32px;
    background: #5D0282;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Soleil-Leger;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    border-style: none;
    padding: 15px 32px;

    &:hover {
        cursor: pointer;
    }
}

.right_btn {
    height: 48px;
    width: auto;
    margin-top: 32px;
    background: #fff;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Soleil-Leger;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #5D0282;
    border-style: none;
    padding: 15px 32px;
    border: 1px solid #5D0282;

    &:hover {
        cursor: pointer;
    }
}

@import "./Header.responsive";