@import "variables";
.submit_form_btn {
	max-width: 400px;
	width: 100%;
	height: 48px;
	margin-top: 32px;
	background: #5d0282;
	border-radius: 84px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Soleil-Regular;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	border-style: none;

	&:hover {
		cursor: pointer;
	}

	@media screen and (max-width: $mobile-breakpoint) {
		max-width: 300px;
		font-size: 14px;
		padding: 0 16px;
	}
}
.subtitle_in_form{
	margin-top: 20px;
}
.row_container{
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0 4%;
}
.information_text_in_form{
  margin-bottom: 32px;
}
.row_container_gross{
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4%;
}

.input_form8{
  margin-bottom: 0px;
}

.year_container{
padding: 20px 0px;
}
.row_inputs_container {
  display: flex;
  width: 100%;
  font-family: Soleil-Regular;
  gap: 4%;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #5d0282;
    margin-bottom: 12px;
  }

  .label_plus_input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    max-width: 200px;
    width: 100%;
  }

  .testInput {
    input[type="text"],
    input[type="number"],
    input[type="email"],
    textarea {
      font-family: Soleil-Leger;
      width: 100%;
      min-height: 32px;
      border-radius: 4px;
      color: #495057;
      border: #ccc 1px solid;
      outline: none;
      padding-left: 13.712px;
      font-size: 15px;

      &:disabled {
        background-color: #e9ecef;
      }

      &:focus {
        outline: 3px solid#bad9fd;
      }
    }

    // Hide arrows for input type number
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .input_container > div > div {
    min-height: 32px;
  }

  .input_container div > div > div > input {
    min-height: 32px;
  }

  .input_container div > div > div > span {
    display: flex;
    align-items: center;
  }
}

.subtitle {
  font-family: Soleil-Leger; 
  font-weight: 600; 
  font-size: 18px; 
  line-height: 130%; 
  color: rgb(25, 0, 56);
}

.submit_btn_to_right {
  display: flex;
  justify-content: right;
  padding-bottom: 32px;
}

.condition {
  font-size: 12px;
  line-height: 1.4;
  margin-bottom: 15px;
  color: #8f8f8f;
}

.form_text_description {
  padding : 14px 0px;
  font-size: 12px;
}

.less_margin_bottom {
  margin-bottom: 5px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .row_container{
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 4%;
  }
  .row_container_gross{
    padding-top: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 0 4%;
  }

  .input_form8{
    margin-bottom: 20px;
  }

}

@import "./Form8.responsive";
