@import "variables";
.container {
  background: #f5f7fa;
  h1 {
    max-width: 100%;
    padding-bottom: 32px;
  }

  .wrapper {
    padding: 0 60px 60px;
    display: flex;
    gap: 60px;
    overflow-x: auto;
    &__colLeft {
      max-width: 550px;
      flex: 0 0 550px;
      .header {
        display: flex;
        justify-content: space-between;
        align-items: end;
        margin-bottom: 22px;
        .headerTitle {
          font-size: 32px;
        }
        .stepLevel {
          white-space: nowrap;
        }
      }
      .boxWrapper {
        background-color: #fff;
        padding: 32px 0;
        margin-bottom: 45px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        form {
          padding: 0 20px;
        }
        .label_plus_info_bulle {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
        .infoBulle {
          position: relative;
          display: inline-block;
          margin-left: 5px;
          svg {
            width: 15px;
            height: 15px;
            path {
              fill: #135f90;
            }
          }

          .infoBulleInfo {
            display: none;
            background: #135f90;
            color: #fff;
            font-size: 13px;
            padding: 10px;
            border-radius: 3px;
            position: absolute;
            width: auto;
            min-width: 340px;
            top: -95px;
            right: -10px;
            &:after {
              content: "";
              width: 0;
              height: 0;
              border-width: 12px 9.5px 0 11.5px;
              border-color: #135f90 transparent transparent transparent;
              border-style: solid;
              position: absolute;
              bottom: -11px;
              right: 10px;
            }
          }
          &:hover {
            .infoBulleInfo {
              display: block;
            }
          }
          @media screen and (max-width: 768px) {
            .infoBulleInfo {
              min-width: 340px;
              top: -100px;
            }
          }
        }
        .formGroup {
          display: flex;
          flex-direction: column;
          margin-bottom: 24px;
          max-width: 330px;
          width: 100%;

          box-sizing: content-box;
          label {
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 600;
            color: #5d0282;
            &:last-child {
              margin-bottom: 0;
            }
          }
          input[type="text"] {
            border: 1px solid #d4d4d4;
            border-radius: 4px;
            padding: 8px;
            &.errorInput {
              border: 2px solid #ea001e;
              outline: none;
            }
          }
          input[type="number"] {
            border: 1px solid #d4d4d4;
            border-radius: 4px;
            padding: 8px;
            &.errorInput {
              border: 2px solid #ea001e;
              outline: none;
            }
          }
          .errorDropDown {
            border: 2px solid #ea001e;
            outline: none;
            border-radius: 4px;
          }
          .error {
            color: #ea001e;
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
      .action {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Mobile

@media screen and (max-width: 1350px) {
  .container {
    .wrapper {
      flex-direction: column;
      padding: 0 60px 60px;
      display: flex;
      gap: 30px;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .container {
    .wrapper {
      padding: 0;
      margin-bottom: 50px;
      &__colLeft {
        max-width: 100%;
        flex: 1;
        .header {
          .headerTitle {
            padding: 0 20px;
          }
        }
        .boxWrapper {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}
