.nav_bloc {
    position: relative;
    width: 100vw;
    padding: 10px;
    display: flex;
    cursor: pointer;

    &:hover {
        background: #5f3e86;
    }
}

.content_container {
    display: flex;

    img {
        width: 28px;
        height: 24px;
    }

    .name {
        display: flex;
        align-items: center;
        margin-left: 10px;
    }
}