.card_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 8px;
    border: 1px dashed #9F9EB7;
    margin-bottom: 24px;
    text-size-adjust: 100%;
    word-break: break-word;
    width: 60%;
    
    .status {
        font-family: Soleil-Leger;
        font-size: 14px;
        line-height: 16px;
        color: #190038;
        height: 16px;
        margin-bottom: 8px;
    }

    .cv_id {
        font-family: Soleil-Medium;
        font-weight: 600;
        font-size: 16px;
        color: #190038;
        height: 24px;
    }

    .name {
        font-family: Soleil-Medium;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #5D0282
    }

    .edit_link {
        margin-top: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 50px;
        a {
            text-transform: uppercase;
        display: flex;
        align-items: center;
        font-family: Inseec;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #190038;
        text-decoration: none;
        background-repeat: no-repeat;
        background-position: top 50% right 30%;
        }
    }
}

// Responsive import
@import "./Card.responsive";