@import "../../../variables";

@media screen and (max-width: 1350px) {
	.cv_list_first_pane {
		display: flex;
		flex-direction: column;
		.col {
			.label_plus_input_container {
				width: 100%;
			}
			&.allWidthCol {
				order: -1;
			}
		}
	}
	.cv_list_info {
		grid-template-columns: repeat(2, 1fr);
		gap: 25px;
		&.threeCols {
			grid-template-columns: repeat(2, 1fr);
		}
	}
	.savoir_etre_container {
		gap: 20px;
		flex-direction: column;
		.label_plus_input_container {
			max-width: 100%;
		}
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.cv_list_container {
		padding: 0 20px;
	}
	.cv_list_info {
		grid-template-columns: 1fr;
		gap: 25px;
		&.threeCols {
			grid-template-columns: 1fr;
		}
	}
	.button_container {
		align-items: center;
		flex-direction: column;
		button {
			margin-bottom: 15px;
		}
	}
	.savoir_etre_container {
		.label_plus_input_container {
			max-width: 100%;
		}
	}
}
