@import "../../../../variables";

.wrapper {
	height: calc(100vh - $mobile-nav-height);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow-y: auto;
	padding-bottom: 40px;
}

.burger_item {
	padding: 10px;
}
