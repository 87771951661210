.main_container {
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	font-family: Soleil;
}

.profil_img {
	margin-right: 5px;
	width: 47px;
	height: 47px;
	background: #9f9eb766;
	border-radius: 100%;
	padding: 8px;
}

.btnCnx {
	background: none;
	color: #fff;
	border: none;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
	margin-left: auto;
	display: flex;
	cursor: pointer;
}

.arrow_container {
	cursor: pointer;
}

.signout_dropdown {
	position: absolute;
	color: black;
	background-color: #FFFFFF;
	border-radius: 2px;
	padding: 15px;
	width: 95%;
	top: 100%;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	font-size: 13px;

	&:hover {
		cursor: pointer;
	}
}

// Responsive import
@import "./_SignOutBtn.responsive.scss";
