@import "variables";

.main_container {
	background-color: #f8f8fc;
	padding: 24px 32px 0px;
	justify-content: space-between;
	margin-top: 30px;
	border: 1px solid;
	border-radius: 5px;
}

.section_title {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-bottom: 5px;
	cursor: pointer;
}

.title {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 14px;
	line-height: 130%;
	margin-bottom: 5px;
}

.top_section {
	padding-bottom: 15px;
}

.pin_status_success {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 8px;
	background-color: green;
	margin-bottom: 2px;
}
.pin_status_pending {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 8px;
	background-color: grey;
	margin-bottom: 2px;
}
.pin_status_error {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 8px;
	background-color: red;
	margin-bottom: 2px;
}

.dropdown_icon {
	margin-left: auto;
	img {
		width: 12px;
		height: 8px;
	}
	.arrowUp {
		transform: rotate(180deg);
	}
}
.optional{
	margin-top: 5px
}
.subTitle {
	font-family: Soleil-Regular;
	font-weight: 200;
	font-size: 15px;
	color: black;
}

@media screen and (max-width: $mobile-breakpoint) {
	.section_title {
		align-items: center;
	}
	.dropdown_icon {
		img {
			width: 12px;
			height: 8px;
		}
	}
}

@media screen and (max-width: 425px) {
	.section_title {
		align-items: center;
		.title {
			inline-size: 170px;
			overflow: hidden;
		}
	}
}
