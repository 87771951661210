// .third_container
.third_container {
    display: flex;
    flex-direction: column;
    padding: 52px 30px 28px;
}

.title_third_container {
    padding-left: 30px;
    margin-bottom: 40px;
}

// .cards_container
.cards_container {
    width: 100%;
    display: flex;
    gap: 5%;
    flex-wrap: wrap;
    text-decoration: none;
}

@import "./ConfirmationFormsPage.responsive";