.main_container {
  padding: 0 60px 60px;
}

.title_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 550px;
  margin-bottom: 22px;
}
.label_plus_info_bulle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form_container {
  max-width: 550px;
  background-color: #f8f8fc;
  padding: 32px 0;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .infoBulle {
      position: relative;
      display: inline-block;
      margin-left: 5px;
      svg {
        width: 15px;
        height: 15px;
        path {
          fill: #135f90;
        }
      }

      .infoBulleInfo {
        display: none;
        background: #135f90;
        color: #fff;
        font-size: 13px;
        padding: 10px;
        border-radius: 3px;
        position: absolute;
        width: auto;
        min-width: 340px;
        top: -70px;
        right: -10px;
        &:after {
          content: "";
          width: 0;
          height: 0;
          border-width: 12px 9.5px 0 11.5px;
          border-color: #135f90 transparent transparent transparent;
          border-style: solid;
          position: absolute;
          bottom: -11px;
          right: 10px;
        }
      }
      &:hover {
        .infoBulleInfo {
          display: block;
        }
      }
      @media screen and (max-width: 768px) {
        .infoBulleInfo {
          width: 250px;
          min-width: 250px;
          top: -85px;
        }
      }
    }
  }
}

.checkbox_container {
  display: flex;
  font-family: Soleil-Leger;
  font-size: 13px;
  align-items: center;

  .checkbox_text {
    margin-left: 10px;
  }
}

.inputs_container {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-bottom: 24px;
  font-family: Soleil-Regular;

  label {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #5d0282;
    margin-bottom: 12px;
  }

  .label_plus_input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
  .label_plus_text_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    p {
      color: #222222;
      font-size: 0.8125rem;
      font-family: Soleil-Regular;
    }
  }
  .label_test_input_container {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    flex: 1;
  }
}

.textarea {
  font-family: Soleil-Regular;
  color: #190038;
  margin-bottom: 8px;
  text-align: center;
}

.list_intro_text {
  list-style: disc;
  list-style-position: initial;
  list-style-image: initial;
  list-style-type: disc;
  margin-top: -40px;
  margin-left: 30px;
  font-family: Soleil-Regular;
}

@import "./Step1.responsive";
