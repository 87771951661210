@import "../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.navbar {
		height: $mobile-nav-height;
		position: fixed;
		z-index: 999;
		width: 100%;
		.side_connect {
			display: none;
		}
	}

	.logo_container {
		display: block;
	}

	.burger_icon {
		display: block;
		width: 30px;
		height: 23px;
	}

	.profil {
		color: #fff;

		margin: 5px 20px 20px;
		img {
			display: none;
		}
	}

	.right_container {
		display: none;
	}
}
