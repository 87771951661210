.header_container {
	padding: 42px 30px 90px;
	background-position: top -58px right -18px;
	min-height: 335px;
}

.header_content {
	padding-left: 30px;

	h1 {
		padding-bottom: 20px;
	}

	p {
		font-family: Soleil-Regular;
		font-size: 16px;
		color: #190038;
		padding-bottom: 40px;
		max-width: 492px;
	}
}

@import "./Header.responsive";
