.nav_bloc {
	position: relative;
	padding: 10px;
	display: flex;
	user-select: none;

	&:hover {
		background: rgba(255, 255, 255, 0.1);

		.sub_nav {
			transform: translateX(245px);
		}
	}
}

.sub_nav {
	width: 240px;
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	background: #190038;
	color: white;
	transform: translateX(-100%);
	transition: transform 0.2s ease-in-out;
	text-align: center;

	li {
		display: flex;
		align-items: center;
		// justify-content: center;
		padding: 10px;
		border-left: 2px solid transparent;
		height: 44px;

		&:hover {
			border-left: 2px solid white;
			background: rgba(255, 255, 255, 0.1);
		}

		img {
			position: absolute;
			left: 8%;
		}

		span {
			margin-left: 45px;
		}
	}
}

.content_container {
	display: flex;

	img {
		width: 28px;
		height: 24px;
	}

	.name {
		display: flex;
		align-items: center;
		margin-left: 10px;
	}
}

.arrow {
	position: absolute;
	left: 89%;
	top: 28%;
	height: 19px;
	width: 22px;
}
