.side_nav {
    position: fixed;
    background: #190038;
    min-width: 245px;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 10;
    font-size: 14px;
    color: white;

    a {
        color: white;
    }
}

.wrapper {
    background: #190038;
    height: 100vh;
    width: 245px;
}

.logo_container {
    height: 80px;
    position: relative;
    left: 20px;
    padding-top: 18px;

    img {
        width: 179px;
        height: 45px;
    }
}

.nav_blocs_container {
    margin-top: 20px;
}

.footer_sidenav_container {
    position: absolute;
    bottom: 0;
}