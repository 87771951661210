// Breakpoints
$mobile-breakpoint: 768px;

// Nav
$mobile-nav-height: 60px;

// Sidenav
$sidenav-width: 245px;

// Backgrounds colors
$body-bg-color: #FFFFFF;

// Titles fonts sizes
$h1-font-size: 58px;
$h1-font-size-mobile: 36px;
$h2-font-size: 38px;