@import "../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    // .third_container
    .third_container {
        padding: 52px 16px 28px;
    }

    .cards_container {
        flex-direction: column;
    }

    .title_third_container {
        padding-left: 0;
    }
}