// .cv_list_container
.cv_list_container {
	padding: 0 60px 60px;
	// background-color: #FFF;
	.divline_h2_container {
		h2 {
			margin-bottom: 54px;
		}
	}
}

// .cards_container
.cards_container {
	padding: 30px;
	background-color: #f8f8fc;
	display: flex;
	flex-wrap: wrap;
	gap: 5%;
}

// Responsive import
@import "./CheckYourCV.responsive";
