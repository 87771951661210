@import "variables";

//tablet
@media screen and (max-width: 1350px) {
	.filter_wrapper,
	.filterResult {
		overflow-x: auto;
		width: 1300px;
	}
}

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.container {
		padding: 0;
		white-space: inherit;
	}
	.divline_h2_container {
		padding: 0 30px;
	}
	.filterResult {
		width: 100%;
	}
	.btnFilter {
		display: flex;
		justify-content: center;
	}
	.filter_wrapper {
		width: 100%;
		.subForm {
			&__row {
				grid-template-columns: 1fr;
				&__threeCol {
					grid-template-columns: 1fr;
				}
			}
		}
	}

	.containerResult {
		background: #f8f8fc;
		padding: 32px 16px;
		&__head {
			display: none;
		}
		.result {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			background-color: #ffffff;
			box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
			border-radius: 10px;
			margin-bottom: 30px;
			padding: 16px;
			border: none;
			&__body {
				padding: 0;
				&__id {
					padding-bottom: 10px;
				}
				&__link {
					align-self: center;
				}
			}
		}
	}
	// .containerTable {
	// 	background: #f8f8fc;
	// 	padding: 32px 16px;
	// }
	// .table_wrapper {
	// 	.tableOffre {
	// 		&__head {
	// 			display: none;
	// 		}
	// 		&__row {
	// 			display: flex;
	// 			flex-direction: column;
	// 			align-items: flex-start;
	// 			background-color: #ffffff;
	// 			box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
	// 			border-radius: 10px;
	// 			margin-bottom: 30px;
	// 			padding: 16px;
	// 			border: none;
	// 			td {
	// 				padding: 0;
	// 				&:last-child {
	// 					padding: 12px;
	// 				}
	// 			}
	// 		}
	// 		&__body {
	// 			&__link {
	// 				width: 100%;
	// 			}
	// 		}
	// 	}
	// }
}
