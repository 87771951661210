@import "variables";

//tablet
@media screen and (max-width: 1350px) {
	.containerTable,
	.filter_wrapper {
		width: 1300px;
		overflow-x: auto;
	}
}

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.container {
		padding: 0;
	}
	.divline_h2_container {
		padding: 0 30px;
	}
	.btnFilter {
		display: flex;
		justify-content: center;
	}
	.filter_wrapper {
		width: 100%;
		.subForm {
			grid-template-columns: 1fr;
		}
	}
	.containerTable {
		background: #f8f8fc;
		padding: 32px 16px;
		width: 100%;
	}
	.wrapper {
		.offreList {
			&__head {
				display: none;
			}
			&__row {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				background-color: #ffffff;
				box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
				border-radius: 10px;
				margin-bottom: 30px;
				padding: 16px;
				border: none;
				gap: 0;
			}
			&__body {
				&__name {
					margin-bottom: 15px;
				}
				&__link {
					width: 100%;
					margin-top: 15px;
				}
			}
		}
	}
}
