@import "../../../../../../variables";

// Mobile
@media screen and (max-width:  768px) {
    .card_container {
        margin-bottom: 16px;
        height: 180px;
        text-size-adjust: 100%;
        font-size: 13px;
        word-break: break-word;
        width: 80%;
    }
}