.navbar {
	background: #190038;
	height: 80px;
	padding: 0px 20px;
	display: flex;
	align-items: center;
	color: white;
	position: relative;
    z-index: 2;
}

.link {
	color: white;
	margin-right: 20px;
	text-decoration: none;
}

.logo_container {
	display: none;

	img {
		width: 140px;
	}
}

.burger_icon {
	position: absolute;
	right: 3%;
	display: none;
}

.profil {
	display: flex;
	align-items: center;
	img {
		margin-right: 10px;
	}
}

.right_container {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: auto;
}

.separator {
	width: 1px;
    height: 40px;
    background-color: #EBEDFF;
	margin: 0 22px
}

// Responsive import
@import "./_Navbar.responsive.scss";
