@import "../../../variables";

input#title {
	height: 38px;
}

input#workStudyProgram {
	height: 38px;
}

.cv_list_container {
	padding-left: 3.8rem;
	padding-right: 3.8rem;
}
textarea {
	height: 80px;
	border-color: #d4d4d4;
	border-radius: 5px;
	padding: 15px;
	resize: none;
}
textarea {
	height: 80px;

	resize: none;
}

.margin_h2 {
	margin-bottom: 30px;
}

.button_container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-bottom: 3rem;
}

.form_container {
	padding: 2rem;
	background-color: #f8f8fc;

	form {
		display: flex;
		flex-direction: column;
	}
}

.subtitle_cv {
	margin-bottom: 24px;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 18px;
	line-height: 130%;
	color: #06073a;
	text-transform: uppercase;
}
.cv_list_first_pane {
	display: grid;
	gap: 50px;
	grid-template-columns: repeat(2, 1fr);
	.col {
		display: flex;
		flex-wrap: wrap;
		gap: 15px 50px;
		.label_plus_input_container {
			display: flex;
			flex-direction: column;
			width: calc(50% - 50px);
		}
		&.Rightcol {
			flex-direction: column;
			.label_plus_input_container {
				width: 100%;
			}
		}
		&.allWidthCol {
			.label_plus_input_container {
				& * {
					width: 100%;
				}
			}
		}
	}
}
.cv_list_info {
	display: grid;
	gap: 50px;
	grid-template-columns: repeat(4, 1fr);
	margin-bottom: 25px;
	&.threeCols {
		grid-template-columns: 22% 23% 26%;
	}
	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		input {
			border: 1px solid #d4d4d4;
			border-radius: 4px;
			padding: 10px;
		}
	}
}

.cv_thirdsublist_first_pane {
	margin-bottom: 25px;
	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		max-width: 25%;
		flex: 0 0 25%;
	}
}

.label_padding {
	padding-bottom: 0.5rem;
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.125rem;
	color: #5d0282;
}

.cv_separator {
	background: #9f9eb7;
	width: 100%;
	height: 0.063rem;
	margin: 1.5rem 0;
}

.subtitle_cv_votreprofil {
	margin-bottom: 1.5rem;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 1.125rem;
	line-height: 1.3rem;
	color: #06073a;
	text-transform: uppercase;
	position: relative;
}

.subtitle_cv_experienceacademiques {
	margin-bottom: 1.5rem;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.3rem;
	color: #06073a;
	text-transform: uppercase;
}

.experiences_card_container {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	gap: 5%;
}

.experiences_card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 8px;
	border: 1px dashed #9f9eb7;
	margin-bottom: 24px;
}

.title_experience_card {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #190038;
	margin-bottom: 8px;
	// TO MAKE 3 LINES
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100px;
}
.subtitle_experience_card {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #5d0282;
	margin-bottom: 8px;
	// TO MAKE 3 LINES
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 100px;
}

.date_experience_card {
	display: flex;
	flex-direction: column;
	// width: 157.5px;
	// height: 32px;
	margin-top: 4px;
	font-family: Soleil-Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #3e0056;
}

.eye_color {
	filter: invert(74%) sepia(6%) saturate(835%) hue-rotate(203deg)
		brightness(86%) contrast(84%);
	width: 1rem;
	height: 1rem;
}

.button_experience_container {
	display: flex;
	align-items: center;
	background-color: transparent;
	border: none;
	cursor: pointer;
	.eye_color {
		margin-right: 5px;
	}
	&:hover {
		.button_experience_text {
			color: black;
		}
		.eye_color {
			filter: none;
			margin-right: 5px;
		}
	}
}

.button_experience_text {
	color: #9f9eb7;
}

.add_experiences {
	background: #9f9eb7;
	font-family: Soleil-Regular;

	line-height: 16px;
	text-align: center;
	text-transform: uppercase;
	color: white;
	padding: 10px 36px;
	box-sizing: border-box;
	border-radius: 74px;
	margin-bottom: 40px;
	cursor: pointer;
	text-decoration: none;
	display: flex;
	border: none;
}
.add_experience_container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 1rem;
}

// Responsive import

.savoir_etre_container {
	display: flex;
	justify-content: space-between;
	width: 100%;
	gap: 50px;
	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		flex: 0 0 50%;
		max-width: calc(50% - 50px);
	}
}

.textArea_extraActivities {
	height: 100%;
}

.saveDocument_master_container {
	display: flex;
	flex-direction: column;
}

.fake_div_forDesign {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
	padding: 1rem;
	width: 1rem;
}

.saveDocument_container {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border: 1px dashed rgb(238, 238, 238);
	padding: 0.3rem;
	border-radius: 5px;
}

.deposerDesFichiers_font {
	font-family: Soleil-Leger;
	font-weight: 300;
	font-size: 16px;
	line-height: 14px;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.fichiers_subtitle_font {
	font-family: Soleil-Leger;
	font-weight: 300;
	font-size: 13px;
	line-height: 14px;
	margin-top: 0.2rem;
}
.saveDocument_button {
	display: flex;
	align-items: center;
	background-color: white;
	border: 1px solid rgb(238, 238, 238);
	padding: 0.3rem;
	border-radius: 5px;
	color: #135f90;
	font-size: 16px;

	&:hover {
		background-color: #d4d4d4;
	}
}

.uploadIcon_color {
	filter: invert(56%) sepia(10%) saturate(922%) hue-rotate(169deg)
		brightness(94%) contrast(88%);
	width: 1rem;
	height: 1rem;
	margin-right: 6px;
}

.consentement_title_container {
	display: flex;
	justify-content: flex-start;
	width: 100%;
}

.consentement_text {
	font-family: Soleil-Leger;
	display: inline;
	vertical-align: middle;
	font-size: 0.6rem;
}

.star_for_consent {
	font-family: Soleil-Leger;
	color: red;
	font-size: 0.6rem;
}

.submitBtn_container {
	display: flex;
	justify-content: flex-end;
	padding-top: 2rem;
}
@import "./ModifyYourCV.responsive";
