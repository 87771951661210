/**
 *   index.scss is the only global scss file.
 *   Use this file to define global scss rules for the whole application.
*/

// IMPORTS FILES SECTION

@import "./reset";
@import "./variables";
@import "../node_modules/react-widgets/styles.css";

// LOCAL FONTS SECTION

@font-face {
	font-family: "Soleil-Regular"; /*Can be any text*/
	src: local("Soleil-Regular"),
		url("./fonts/Soleil-Regular.otf") format("opentype"),
		url("./fonts/Soleil-Regular.woff") format("woff");
}

@font-face {
	font-family: "Soleil-Leger"; /*Can be any text*/
	src: local("Soleil-Leger"), url("./fonts/Soleil-Leger.otf") format("opentype"),
		url("./fonts/Soleil-Leger.woff") format("woff");
}

@font-face {
	font-family: "Inseec-Medium"; /*Can be any text*/
	src: local("Inseec-Medium"),
		url("./fonts/Inseec-Medium.ttf") format("truetype"),
		url("./fonts/Inseec-Medium.woff") format("woff");
}

// GLOBAL SCSS SECTION

*,
::before,
::after {
	box-sizing: border-box;
	word-break: break-word;
}

body {
	background: $body-bg-color;
	font-family: Soleil-Regular;
}

h1 {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 44px;
	line-height: 1.5;
	color: #190038;
	text-transform: uppercase;
	max-width: 495px;

	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 36px;
		line-height: 120%;
	}
}

h2 {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 38px;
	line-height: 130%;
	color: #06073a;
	text-transform: uppercase;

	@media screen and (max-width: $mobile-breakpoint) {
		font-size: 30px;
		line-height: 1.5;
	}
}

h3 {
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 18px;
	line-height: 1.5;
	color: #190038;
	text-transform: uppercase;
}

a {
	text-decoration: none;
}

p {
	font-family: Soleil-Leger;
	line-height: 1.5;
}

.input-like-rw {
	input[type="text"],
	input[type="number"],
	input[type="email"],
	textarea {
		font-family: Soleil-Leger;
		min-height: 38px;
		border-radius: 4px;
		color: #495057;
		border: #ccc 1px solid;
		outline: none;
		padding-left: 13.712px;
		font-size: 15px;

		&:disabled {
			background-color: #e9ecef;
		}

		&:focus {
			outline: 3px solid#bad9fd;
		}
	}

	// Hide arrows for input type number
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	/* Firefox */
	input[type="number"] {
		-moz-appearance: textfield;
	}
}

// react-widgets SECTION
.rw-widget {
	font-family: Soleil-Leger;
}

.rw-list {
	font-family: Soleil-Leger;
}

.rw-listbox {
	max-height: 120px;
}

// react-toastify SECTION
.Toastify__toast-container {
	width: 50%;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
	background-color: #2e844a;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
	background-color: #fe9339;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
	background-color: #ea001e;
}

// react-datepicker SECTION
.react-datepicker__input-container input {
	width: 100%;
	font-family: Soleil-Leger;
	min-height: 38px;
	border-radius: 4px;
	color: #495057;
	border: #ccc 1px solid;
	outline: none;
	padding-left: 13.712px;
	font-size: 15px;

	&:disabled {
		background-color: #e9ecef;
	}

	&:focus {
		outline: 3px solid#bad9fd;
	}
}

// MOBILE SECTION

@media screen and (max-width: $mobile-breakpoint) {
	// react-toastify SECTION
	.Toastify__toast-container {
		width: 100%;
	}
}
