@import "../../variables";

.submit_form_btn {
    width: 400px;
    height: 48px;
    margin-top: 32px;
    background: #5D0282;
    border-radius: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Soleil-Regular;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border-style: none;

    &:hover {
        cursor: pointer;
    }

    @media screen and (max-width: $mobile-breakpoint) {
        width: 300px;
        font-size: 14px;
        padding: 0 16px;
    }
}