@import "variables";

.main_container {
  padding: 0 60px 60px;
  margin-top: 10px;
}

.blue_banner_container {
  padding: 0 60px 10px 60px;
}

.static_part {
  background-color: #f8f8fc;
  padding: 24px 32px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@import "./ModifiableContract.responsive";
