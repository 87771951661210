@import "../../../../variables";
// Tablette
@media screen and (max-width: 1350px) {
	.card_container {
		h3 {
			min-height: 62px;
		}
	}
}
// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.card_container {
		width: 100%;
		h3 {
			min-height: auto;
		}
	}
}
