.main_container {
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	font-family: Soleil;
}

.profil_img {
	margin-right: 5px;
	width: 47px;
	height: 47px;
	background: #9f9eb766;
	border-radius: 100%;
	padding: 8px;
}

.btnCnx {
	background: none;
	color: #fff;
	border: none;
	font-weight: 400;
	font-size: 16px;
	line-height: 42px;
	margin-left: auto;
	display: flex;
	cursor: pointer;
	font-family: "Soleil-Regular";
}

// Responsive import
@import "./_SignInBtn.responsive.scss";
