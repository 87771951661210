@import "variables";
.wrapper {
	background: url("../../assets//bg-faq.png") no-repeat right top;
	background-size: 20%;
}
.header {
	padding: 42px 60px 90px;

	margin-bottom: 90px;
	p {
		max-width: 600px;
		// padding: 36px 0;
	}
	.actionBtn {
		max-width: 600px;
		display: flex;
		justify-content: space-between;
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 15px 35px;
			font-size: 16px;
			line-height: 18px;
			text-align: center;
			text-transform: uppercase;
			border-radius: 74px;
			text-decoration: none;
			background-color: #5d0282;
			color: #fff;
			&.lightBtn {
				background-color: transparent;
				border: 1px solid #5d0282;
				color: #5d0282;
			}
		}
	}
}
.cards {
	background: url("../../../Homepage/assets/bg-advantage.png") no-repeat right;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	padding: 86px 30px 132px;
	display: grid;
	gap: 100px;
	grid-template-columns: repeat(3, 1fr);
	.cardsItem {
		padding: 42px 30px 52px;
		background-color: #190038;
		border-radius: 5px;
		color: white;
		h3 {
			font-family: Inseec-Medium;
			color: white;
			font-weight: 500;
			font-size: 24px;
			margin-bottom: 32px;
			&::before {
				content: "";
				height: 2px;
				width: 87px;
				background-color: #9f9eb7;
				margin-bottom: 40px;
				display: block;
			}
		}
		p {
			font-family: "Soleil-Regular"; /*Can be any text*/
			font-size: 14px;
			line-height: 26px;
		}
	}
}
.discover_container {
	display: flex;
	margin: 0 30px 90px 30px;
	box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
	border-radius: 5px;
}

.discover_trapeze_img {
	width: 385px;
}

.discover_mobile_img {
	display: none;
}

.discover_content_container {
	padding: 38px 0 46px;
}

.discover_content_title {
	font-size: 32px;
	padding-bottom: 15px;
	max-width: 700px;
}

.discover_content_desc {
	font-size: 14px;
	line-height: 16px;
	max-width: 660px;
	color: #3e0056;
}

// .second_container
.second_container {
	padding: 52px 30px 28px;
	background-color: #f8f8fc;
}

.title_second_container {
	padding-left: 30px;
	margin-bottom: 50px;
}

.steps_container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	flex-wrap: wrap;
}

// .third_container
.third_container {
	display: flex;
	flex-direction: column;
	padding: 52px 30px 28px;
}

.title_third_container {
	padding-left: 30px;
	margin-bottom: 40px;
}

// .cards_container
.cards_container {
	width: 100%;
	display: flex;
	gap: 5%;
	flex-wrap: wrap;
	text-decoration: none;
}

// Responsive import
@media screen and (max-width: $mobile-breakpoint) {
	.header {
		background: none;
		margin-bottom: 0;
		padding-bottom: 15px;
		p {
			max-width: 100%;
			padding: 36px 0;
		}
		.actionBtn {
			max-width: 100%;
			flex-direction: column;

			a {
				margin-bottom: 20px;
			}
		}
	}
	.discover_container {
		flex-direction: column;
		margin: 0 30px 70px 30px;
		background: #ffffff;
		border-radius: 10px;
	}

	.discover_content_title {
		font-size: 30px;
	}

	.discover_trapeze_img {
		display: none;
	}

	.discover_mobile_img {
		display: block;
	}

	.discover_content_container {
		padding: 32px 8px;
		width: auto;
	}

	// .second_container
	.steps_container {
		flex-direction: column;
	}

	.title_second_container {
		padding-left: 0;
	}

	.steps_container :nth-child(2) {
		order: 2;
	}
	.steps_container :nth-child(4) {
		order: 4;
	}
	.steps_container :nth-child(6) {
		order: 6;
	}

	// .third_container
	.cards_container {
		flex-direction: column;
	}

	.title_third_container {
		padding-left: 0;
	}

	.cards {
		gap: 20px;
		grid-template-columns: 1fr;
		padding: 0 20px;
	}
}
