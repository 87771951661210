@import "../../../variables";

textarea {
	font-family: Soleil-Regular;
}
.label_modify_modal {
	color: #5d0282;
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	padding: 20px 0px 10px 0px;
	margin-top: 10px;
}

.modal {
	/* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 100; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal_content {
	background-color: #fefefe;
	margin: 5% auto; /* 15% from the top and centered */
	padding: 20px;
	border: 1px solid #888;
	border-radius: 5px;
	max-width: 800px;
	height: auto;
	width: 100%;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.modal_title {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 1rem;
	text-align: center;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 32px;
	line-height: 130%;
	color: #06073a;
}

.modal_close_button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	margin-left: auto;
}

.closeIcon_size {
	width: 1rem;
	height: 1rem;
}

.modal_contentBox {
	max-width: 550px;
	background: #f8f8fc;
	margin: 30px auto 18px auto;

	padding: 20px;
}

.etablissement {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;
	color: #190038;
	margin-bottom: 8px;
}

.formation {
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 14px;
	line-height: 18px;
	color: #5d0282;
	margin-bottom: 8px;
}

.date_modal {
	font-family: Soleil-Regular;
	font-size: 14px;
	line-height: 16px;
	color: #3e0056;
	margin-top: 16px;
}

.country_modal {
	font-family: Soleil-Regular;
	font-size: 14px;
	line-height: 16px;
	color: #3e0056;
	margin-bottom: 18px;
}

.description_modal {
	font-family: Soleil-Regular;
	font-size: 14px;
	line-height: 16px;
	color: #190038;
}

.button_modify_modal_container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.label_plus_input_container {
	display: flex;
	flex-direction: column;
	& > input {
		padding: 8px 10px;
		border: 1px solid #d4d4d4;
		border-radius: 4px;
		font-family: Soleil-Regular;
	}
}
.showModify_btn {
	width: 17rem;
	height: 58px;
	background: #3e0056;
	border-radius: 84px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: Soleil-Regular;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: #ffffff;
	border-style: none;

	&:hover {
		cursor: pointer;
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.modal_content {
		width: calc(100% - 40px);
		margin: 75px auto;
	}
	.modal_title {
		font-size: 20px;
	}
	.showModify_btn {
		width: 300px;
		font-size: 14px;
		padding: 0 16px;
	}
}
