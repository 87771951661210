@import "variables";

.user_name {
  font-family: Inseec-Medium;
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  color: #06073a;
  margin-bottom: 22px;
}

.user_info_container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  gap: 0% 4%;
  width: 100%;
}

@media screen and (max-width: $mobile-breakpoint) {
  .user_info_container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2px;
  }
}
