@import "variables";

@media screen and (max-width: $mobile-breakpoint) {
  .main_container {
    padding: 0 16px 60px;
  }

  .inputs_container {
    width: 100%;
  }
}
