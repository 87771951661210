@import "variables";

// Global application structure for Mobile
@media screen and (max-width: $mobile-breakpoint) {
    .navbar_and_content_container {
        margin-left: 0;
    }

    .main_container_content {
        padding-top: $mobile-nav-height;
    }

    .sidenav_container {
        display: none;
    }
}