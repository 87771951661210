@import "variables";

.output_container {
  width: 100%;
  position: absolute;
  z-index: 9;
  background-color: #fff;
  height: 300px;
  overflow-x: auto;
}
.siret_text {
  font-family: Soleil-Regular;
  font-size: 13px;
  margin-top: 5px;
}

.idcc_group {
  position: relative;
}

.input_margin {
  margin: 0px;
}

.idcc_group .input_padding{
  input[type=text] {
    padding-left: calc(32px + 1.2rem);
  }
}

.output_container {
  width: 100%;
  position: absolute;
  top: bottom;
  z-index: 9;
  background-color: #fff;
  max-height: 300px;
  overflow-x: auto;
}

.output_plus_image_container {
  display: flex;
  padding: 8px;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
  li {
    color: #4d4d4d;
    padding-left: 10px;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}

.search_container,
.siret_container {
  position: relative;
}

.search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}
.idcc_icon {
  position: absolute;
  width: 1.2rem;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}



.back_arrow_icon {
  position: absolute;
  top: 25%;
  right: 10px;
  cursor: pointer;
  .tooltip {
    display: none;
    background: #135f90;
    color: #fff;
    font-size: 12px;
    padding: 10px;
    border-radius: 3px;
    position: absolute;
    top: -56px;
    width: 160px;
    right: 0;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px 9.5px 0 11.5px;
      border-color: #135f90 transparent transparent transparent;
      border-style: solid;
      position: absolute;
      bottom: -11px;
      right: 0px;
    }
  }
  &:hover {
    svg {
      fill: #4d4d4d;
    }
    .tooltip {
      display: block;
    }
  }
}

.container {
  .BoxValided {
    display: flex;
    align-items: flex-start;
    padding-bottom: 24px;
    label {
      font-size: 13px;
      line-height: 1.6;
      color: #5a5a5a;
    }
  }
  .input_group_line {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 100%;
    gap: 0 4%;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 0 4%;
    width: 100%;
  }
  .formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
    position: relative;
    label {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #5d0282;
    }
    input[type="text"] {
      padding: 10px;
      border: 1px solid #d4d4d4;
      border-radius: 4px;
    }
    .error {
      color: #ea001e;
      font-size: 12px;
      line-height: 18px;
    }
    .errorInput {
      border: 2px solid #ea001e;
      outline: none;
    }

    .searchCodeIdcc {
      width: 100%;
      input {
        width: 100%;
        padding-right: 30px;
      }
    }
  }
}

.info_label {
  display: flex;
  position: relative;
}

.infoBulle {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 1;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: #135f90;
    }
  }

  .infoBulleInfo {
    display: none;
    background: #135f90;
    color: #fff;
    font-size: 13px;
    padding: 10px;
    border-radius: 3px;
    position: absolute;
    top: -56px;
    width: 320px;
    right: 0;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px 9.5px 0 11.5px;
      border-color: #135f90 transparent transparent transparent;
      border-style: solid;
      position: absolute;
      bottom: -11px;
      right: 10px;
    }
  }
  &:hover {
    .infoBulleInfo {
      display: block;
    }
  }
}

.submit_btn_to_right {
  display: flex;
  justify-content: end;
  padding-bottom: 32px;
  button {
    text-transform: lowercase;
    max-width: 320px;
  }
}

.checkbox_container{
  margin-bottom: 0px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .idcc_group {
    margin-bottom: 38px;
  }

  .container {
    .input_group_line {
      grid-template-columns: 1fr;
      grid-gap: 2px;
    }
    .wrapper {
      grid-template-columns: 1fr;
      gap: 0 4%;
      width: 100%;
      .formGroup {
        max-width: 100%;
      }
    }
    .info_label {
      display: flex;
    }
    .infoBulleInfo {
      display: none;
      background: #135f90;
      color: #fff;
      font-size: 13px;
      padding: 10px;
      border-radius: 3px;
      position: absolute;
      width: 320px;
      right: -100px;
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-width: 12px 9.5px 0 11.5px;
        border-color: #135f90 transparent transparent transparent;
        border-style: solid;
        position: absolute;
        bottom: -11px;
        right: 10px;
      }
    }
  }
  .infoBulle {
    position: relative;
    margin-left: 5px;
    left: auto;
    top: auto;
  }
}
