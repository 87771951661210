@import "variables";
.main_container {
  padding: 0 60px 60px;
  margin-top: 10px;
}

.blue_banner_container {
  padding: 0 60px 10px 60px;
}

@media screen and (max-width: $mobile-breakpoint) {
  .blue_banner_container {
    padding: 0 10px 10px 10px;
  }
}

.static_part {
  background-color: #f8f8fc;
  padding: 24px 32px 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user_name {
  font-family: Inseec-Medium;
  font-weight: 500;
  font-size: 32px;
  line-height: 130%;
  color: #06073a;
  margin-bottom: 22px;
}

.user_info_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 4%;
}

.user_info_card {
  width: 22%;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  .label {
    font-family: Soleil-Regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    color: #5d0282;
    margin-bottom: 12px;
  }

  .info_container {
    clear: left;
    position: relative;
    font-family: Soleil-Leger;
  }
}

.checkbox_part {
  padding-top: 30px;
  //margin-top: 30px;
}
.checkbox_row {
  display: flex;
  flex-direction: column;
}

.checkbox_container {
  margin: 0px 0px 35px 0px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.checkbox_text {
  display: inline;
  vertical-align: middle;
  font-size: var(--lwc-fontSize3, 0.8125rem);
  font-family: Soleil-Leger;
  margin-left: 5px;
}

.submit_brn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoBulle {
  // position: absolute;
  // right: -20px;
  // z-index: 1;
  // top: 0;
  position: relative;
  display: inline-block;
  margin-left: 5px;
  svg {
    width: 15px;
    height: 15px;
    path {
      fill: #135f90;
    }
  }

  .infoBulleInfo {
    display: none;
    background: #135f90;
    color: #fff;
    font-size: 13px;
    padding: 10px;
    border-radius: 3px;
    position: absolute;
    width: 320px;
    top: -125px;
    right: -10px;
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-width: 12px 9.5px 0 11.5px;
      border-color: #135f90 transparent transparent transparent;
      border-style: solid;
      position: absolute;
      bottom: -11px;
      right: 10px;
    }
  }
  &:hover {
    .infoBulleInfo {
      display: block;
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .user_info_container {
    flex-direction: column;
  }

  .user_info_card {
    width: 100%;
  }
  .main_container {
    padding: 0;
  }
  .submit_brn {
    padding-bottom: 60px;
  }
}
