@import "variables";
.container {
	background: #f5f7fa;
	.wrapper {
		padding: 0 60px 60px;
		max-width: 550px;
		box-sizing: content-box;
		.listInfo {
			display: flex;
			flex-direction: column;
			margin-bottom: 32px;
			max-width: 330px;
			width: 100%;

			.info {
				font-size: 13px;
				color: #222222;
			}

			&__name {
				font-size: 14px;
				font-weight: 600;
				color: #5d0282;
				display: block;

				&:after {
					content: "";
					width: 100%;
					height: 1px;
					display: block;
					margin: 6px 0;
					background-color: #9f9eb7;
				}
			}
		}
		.header {
			display: flex;
			justify-content: space-between;
			align-items: baseline;
			margin-bottom: 22px;
			.headerTitle {
				font-size: 32px;
			}
		}
		.boxInfo {
			background-color: #467dd7;
			padding: 12px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-bottom: 20px;
			font-size: 13px;
			color: #fff;
			svg {
				width: 33px;
				path {
					fill: #fff;
				}
			}
		}
		.boxDisableInfo {
			background: #fff;
			padding: 36px 36px 4px;
			.listItem {
				display: flex;
				flex-direction: column;
				max-width: 330px;
				margin: 0 auto 32px;
				h6 {
					margin-bottom: 12px;
					font-size: 14px;
					font-weight: 600;
					color: #5d0282;
				}
				input {
					padding: 6px 10px;
					border: 1px solid #c9c9c9;
					border-radius: 4px;
				}
			}
		}
		.next {
			display: flex;
			justify-content: center;
			button {
				text-transform: none;
			}
		}
		.btns_container {
			display: flex;
			flex-direction: column;
			width: 100%;
			align-items: center;
		}
	}
}

@media screen and (max-width: $mobile-breakpoint) {
	.container {
		.wrapper {
			padding: 0 20px 20px;
		}
	}
}
