.formGroup {
    display: flex;
    flex-direction: column;
    margin-bottom: 38px;
    position: relative;
    label {
      margin-bottom: 12px;
      font-size: 14px;
      font-weight: 600;
      color: #5d0282;
    }
    input[type="text"],
    input[type="number"],
    input[type="email"],
    textarea {
      width: 100%;
      &.errorInput {
        border: 2px solid #ea001e;
        outline: none;
        border-radius: 4px;
      }
    }
    .dateInput {
      input {
        border: none;
      }
    }
    input:disabled {
      cursor: not-allowed;
    }
    .errorInput {
      border: 2px solid #ea001e;
      outline: none;
      border-radius: 4px;
    }
    .nb {
      font-size: 10px;
      color: grey;
      margin-top: 5px;
    }
  }
  