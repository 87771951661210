@import "variables";
.error {
	color: #ea001e;
	font-size: 12px;
	line-height: 18px;
}
.checkboxInform{
	margin-top: 5px;
	margin-bottom: 10px;
}
.wrapper {
	padding-top: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 0 4%;
}
textarea {
	&.errorInput {
		border: 2px solid #ea001e;
		outline: none;
		border-radius: 4px;
	}
}
.fullContainer {
	.subtitle {
		margin-bottom: 12px;
		font-size: 14px;
		font-weight: 600;
		color: #5d0282;
	}
	.labelCheckBox {
		font-size: 13px;
		color: #5a5a5a;
		margin-bottom: 20px;
		display: inline-flex;
	}
	.explication {
		margin-top: -15px;
		font-size: 15px;
		margin-bottom: 15px;
	}
}
.box {
	.formGroup {
		label {
			margin-bottom: 12px;
			font-size: 14px;
			font-weight: 600;
			color: #5d0282;
		}
		flex-direction: column;
		display: flex;
		margin-bottom: 20px;
	}
}
.submit_btn_to_right {
	display: flex;
	justify-content: end;
	padding-bottom: 32px;
	button {
		text-transform: lowercase;
		max-width: 320px;
	}
}
.infoBulle {
	position: absolute;
	left: 175px;
	z-index: 1;
	top: 0;
	svg {
		width: 15px;
		height: 15px;
		path {
			fill: #135f90;
		}
	}

	.infoBulleInfo {
		display: none;
		background: #135f90;
		color: #fff;
		font-size: 13px;
		padding: 10px;
		border-radius: 3px;
		position: absolute;
		top: -56px;
		width: 320px;
		right: 0;
		&:after {
			content: "";
			width: 0;
			height: 0;
			border-width: 12px 9.5px 0 11.5px;
			border-color: #135f90 transparent transparent transparent;
			border-style: solid;
			position: absolute;
			bottom: -11px;
			right: 10px;
		}
	}
	&:hover {
		.infoBulleInfo {
			display: block;
		}
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.wrapper {
		display: grid;
		grid-template-columns: 1fr;
		.formGroup {
			max-width: 100%;
		}
	}
}
