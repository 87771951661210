@import "../../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    // .discover_container
    .discover_container {
        flex-direction: column;
        margin: 0 30px 70px 30px;
        background: #FFFFFF;
        border-radius: 10px;
    }

    .discover_content_title {
        font-size: 30px;
    }

    .discover_trapeze_img {
        display: none;
    }

    .discover_mobile_img {
        display: block;
    }

    .discover_content_container {
        padding: 32px 8px;
        width: auto;
    }

    // .second_container   
    .steps_container {
        flex-direction: column;
    }

    .title_second_container {
        padding-left: 0;
    }

    .steps_container :nth-child(2) {order: 2;}
    .steps_container :nth-child(4) {order: 4;}
    .steps_container :nth-child(6) {order: 6;}

    // .third_container
    .cards_container {
        flex-direction: column;
    }

    .title_third_container {
        padding-left: 0;
    }
}