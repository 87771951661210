@import "variables";
.loading_modal {
	/* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 100; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%;
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
	display: flex;
	justify-content: center;
	align-items: center;
}

.tmp {
	padding-left: 3.8rem;
	padding-right: 3.8rem;
}

/* Modal Content/Box */
.modal_content {
	background-color: #fefefe;
	padding: 20px;
	border: 1px solid #888;
	border-radius: 5px;
	max-width: 60%;
	height: auto;
}

.loading_modal_content {
	background-color: #fefefe;
	border: 1px solid #888;
	border-radius: 5px;
	width: 640px;
	height: auto;
	max-width: 100%;
}

/* The Close Button */
.close {
	color: #aaa;
	float: right;
	font-size: 28px;
	font-weight: bold;
}

.close_btn {
	background-color: #0099de;
	max-width: 280px;
	height: 35px;
	border-radius: 0.25rem;
	text-align: center;
	padding: 0px, 16px;
	cursor: pointer;
	color: #fefefe;
	position: relative;
	float: right;
}

.ok_close_btn {
	background-color: #0099de;
	max-width: 50px;
	margin-left: 90%;
	border-radius: 0.25rem;
	text-align: center;
	padding: 0px, 16px;
	cursor: pointer;
	color: #fefefe;
}

.bottom_container {
	display: flex;
	flex-direction: row;
	//align-items: center;
	justify-content: space-between;
	font-size: 13px;
	padding: 0 20px 18px 20px;
}

.close_loading_modal {
	background-color: #0099de;
	width: 100px;
	//margin-left: 75%;
	border-radius: 0.25rem;
	text-align: center;
	padding: 4px;
	cursor: pointer;
	color: #fefefe;
	//justify-content: flex-end;
}

.close_loading_modal_pending {
	background-color: grey;
	width: 100px;
	//margin-left: 75%;
	border-radius: 0.25rem;
	text-align: center;
	padding: 0px, 16px;
	cursor: pointer;
	color: #fefefe;
	//justify-content: flex-end;
}

.close_loading_modal_error {
	background-color: orange;
	width: 100px;
	//margin-left: 75%;
	border-radius: 0.25rem;
	text-align: center;
	padding: 0px, 16px;
	cursor: pointer;
	color: #fefefe;
	//justify-content: flex-end;
}

.close_text {
	position: relative;
	display: inline-flex;
	font-family: Soleil-Regular;
}

.close:hover,
.close:focus {
	color: black;
	text-decoration: none;
	cursor: pointer;
}

.modal_title {
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-family: Inseec-Medium;
	font-weight: 500;
	font-size: 20px;
	line-height: 130%;
	color: #06073a;
}

.modal_close_button {
	background-color: transparent;
	border: none;
	cursor: pointer;
	display: flex;
	margin-left: auto;
}

.closeIcon_size {
	width: 1rem;
	height: 1rem;
}

.separator_top {
	max-width: 100%;
	height: 2px;
	background-color: grey;
	margin-top: 30px;
	opacity: 0.5;
}

.separator_bottom {
	max-width: 100%;
	height: 2px;
	background-color: grey;
	margin-bottom: 18px;
	opacity: 0.5;
}

.modal_contentBox {
	padding: 20px;
}

.modal_loading_contentBox {
	max-width: 850px;
	padding: 20px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.sub_load_content {
	display: flex;
	flex-direction: row;
}

.img_file {
	width: 2.5rem;
	height: 3.5rem;
	margin-top: auto;
	margin-bottom: auto;
}

.status_img {
	width: 20px;
	height: 20px;
	margin-top: auto;
	margin-bottom: auto;
}

.file_name {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 260px;
}

.img_txt_file {
	display: flex;
	flex-direction: column;
	font-size: 13px;
}

.loading_bar_pending {
	width: 30%;
	height: 10px;
	background-color: grey;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 10px;
}

.loading_bar_error {
	width: 30%;
	height: 10px;
	background-color: orange;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 10px;
}

.loading_bar_success {
	width: 30%;
	height: 10px;
	background-color: #0099de;
	margin-top: auto;
	margin-bottom: auto;
	border-radius: 10px;
}

.text_area {
	font-family: Soleil-Regular;
	color: black;
	//margin-bottom: 8px;
	text-align: center;
	padding: 8px;
}

.text_file_area {
	font-family: Soleil-Regular;
	color: black;
	margin-bottom: 8px;
}

.linear_activity {
	overflow: hidden;
	width: 50%;
	height: 4px;
	background-color: #b3e5fc;
	margin: 20px auto;
}

.determinate {
	position: relative;
	max-width: 100%;
	height: 100%;
	-webkit-transition: width 500ms ease-out 1s;
	-moz-transition: width 500ms ease-out 1s;
	-o-transition: width 500ms ease-out 1s;
	transition: width 500ms ease-out 1s;
	background-color: #03a9f4;
}

@import "./LoadingModal.reponsive";

@media screen and (max-width: 1350px) {
	.file_name {
		width: 200px;
	}
}
@media screen and (max-width: $mobile-breakpoint) {
	.file_name {
		width: 100px;
	}
}
