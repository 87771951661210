@import "../../../../variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
    // .header_container
    .header_container {
        background-image: none;
        padding: 40px 16px 44px 0;
    }

    .header_content {
        h1 {
            padding-bottom: 20px;
            font-size: 36px;
            line-height: 120%;
        }

        p {
            padding-bottom: 28px;
        }
    }

    // .white_squares_container
    .white_squares_container {
        flex-direction: column;
        width: 100%;
        gap: 32px;
        padding: 0 30px 72px 30px;
    }

    .white_square_card {
        width: 100%;
    }
}