@import "variables";
.label_plus_input_container {
	display: flex;
	flex-direction: column;
	margin-bottom: 24px;
}
.custom_input_container {
	position: relative;
	padding: 2px;
	border: 1px dashed #d4d4d4;
	border-radius: 4px;
	max-width: 330px;
	.input_label {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
		.uploadFile {
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: "Soleil-Regular";
			padding: 3px 8px 0 16px;
			// border: 1px solid #d4d4d4;
			// border-radius: 4px;
			font-size: 13px;
			line-height: 1;
			color: #0099de;
			// inline-size: 160px;
			// overflow-wrap: break-word;
			text-align: center;
			min-height: 62px;
			font-weight: 200;
			max-width: 100%;
			// width: 100%;
			// &:hover {
			// 	background-color: rgba(25, 124, 190, 0.05);
			// 	color: #135f90;
			// 	svg {
			// 		path {
			// 			fill: #135f90;
			// 		}
			// 	}
			// }
			svg {
				path {
					fill: #5d0282;
				}
			}
		}
		.dragDrop {
			font-size: 13px;
			line-height: 18.5px;
			margin-left: 8px;
			margin-right: 12px;
			display: block;
			font-weight: 200;
			color: #222222;
			inline-size: 138px;
			overflow-wrap: break-word;
		}
		.supportingDocument_container {
			position: relative;
		}
		.close_position {
			position: absolute;
			width: 10px;
			left: 20px;
			bottom: 24px;
		}
		.buttonDownload_container {
			display: flex;
			justify-content: space-evenly;
			padding-top: 12px;
			align-items: center;
			width: 100%;
		}
		h5 {
			color: #5d0282; 

		}
	}
}
.disabled_div_upload {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgb(233, 236, 239, 0.9);
}
@media screen and (max-width: $mobile-breakpoint) {
	.custom_input_container {
		.input_label {
			.uploadFile {
				min-height: auto;
				max-width: 100%;
				padding: 0 8px;
				svg {
					margin-right: 6px;
				}
			}
			.dragDrop {
				display: none;
			}
			.supportingDocument_container {
				position: relative;
			}
			.close_position {
				position: absolute;
				width: 10px;
				left: 20px;
				bottom: 24px;
			}
		}
	}
}

.img_file {
	width: 2.5rem;
	height: 3.5rem;
	margin-top: auto;
	margin-bottom: auto;
}
