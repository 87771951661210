@import "variables";

.accordion__section {
  display: flex;
  flex-direction: column;
}

.accordion {
  color: #444;
  cursor: pointer;
  padding: 18px 0;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
  p {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.icon {
  width: 20px;
  height: 20px;
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
  border-left: 5px solid rgb(25 0 56 / 10%);
  margin-left: 10px;
}

.accordion__text {
  font-size: 16px;
  line-height: 1.8;
  color: #5d0282;
  padding: 20px 18px 25px;
  margin-left: 60px;
  white-space: pre-line;
  margin-bottom: 32px;
}

.arrow {
  margin-left: auto;
}
@media screen and (max-width: $mobile-breakpoint) {
  .accordion {
    padding: 0 16px;
  }

  .accordion__text {
    margin-left: 20px;
  }
}
