.container {
	padding: 0 60px;
	--gap: 16px;

	h1 {
		max-width: 440px;
		width: 100%;
	}
}

.divline_h2_container {
	margin-bottom: 22px;
	&__subTitle {
		font-size: 32px;
	}
}
.formGroup {
	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 5px;
	}
}

.formGroup {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.filter_wrapper {
	--gap: 15px;
	background-color: #f8f8fc;
	padding: 30px;
	display: flex;
	flex-direction: column;
	gap: var(--gap);
	.col {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.subForm {
		display: grid;
		grid-gap: var(--gap) calc(var(--gap) * 3.5);
		grid-template-columns: repeat(auto-fill, minmax(30%, 1fr));
		font-size: 13px;
	}
	:global {
		.rw-listbox {
			height: 240px;
			font-size: 14px;
			max-height: 240px;
		}
		.rw-list-option {
			padding: 11px 12px;
			display: flex;
			align-items: center;
			font-size: 13px;
		}
	}
}
.btnFilter {
	display: flex;
	justify-content: right;
	button {
		text-transform: initial;
		max-width: 320px;
	}
}
.seeMore {
	color: #06073a;

	img {
		padding-left: 10px;
	}
	&:active,
	&:hover {
		color: #06073a;
	}
}

.wrapper {
	padding-top: 60px;
	.offreList {
		width: 100%;
		margin: 2rem 0;
		overflow: hidden;
		&__head,
		&__row {
			display: grid;
			grid-template-columns: repeat(8, 1fr);
			align-items: center;
			text-transform: uppercase;
			text-align: center;
			font-family: "Inseec-Medium";
			font-size: 14px;
			line-height: 16px;
			gap: 30px;
			color: #190038;
			padding: 20px 0;
			&__name,
			&__level,
			&__date {
				width: 60%;
				margin: auto;
			}
		}
		&__body {
			&__name {
				color: #5d0282;
			}
			& > div {
				&:nth-child(even) {
					background: #f8f8fc;
				}
				&:not(:last-child) {
					border-bottom: none;
				}
			}
			&__level,
			&__contract {
				span {
					&:not(:last-child) {
						&:after {
							content: ", ";
						}
					}
				}
			}
		}

		&__row {
			font-family: "Soleil-Regular";
			text-transform: inherit;
			border: 1px solid #ebedff;
		}
	}
}

.arrowPagination {
	display: flex;
	justify-content: right;
	padding: 20px 0;
	button {
		background: none;
		border: none;
		margin: 0 25px;
		&.arrowLeft {
			img {
				transform: rotate(180deg);
			}
		}
		&:disabled {
			opacity: 0.5;
		}
	}
	.totalPage {
		font-size: 14px;
		color: #3e0056;
		padding-right: 15px;
	}
}

// Responsive import
@import "./CheckVacanciesOffers.responsive";
