.offerWrapper {
	padding: 0 60px 60px;
	--gap: 15px;

	.refOffer {
		color: #5d0282;
	}
	h6 {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 8px;
	}
	.actionWrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 32px 0;
		.lightBtn {
			font-size: 16px;
			line-height: 1;
			text-transform: uppercase;
			color: #5d0282;
			padding: 16px 35px;
			border: 1px solid #5d0282;
			box-sizing: border-box;
			border-radius: 74px;
			text-decoration: none;
			display: flex;
			align-items: center;
			justify-content: center;
			background: none;
			cursor: pointer;
			img {
				margin-right: 10px;
			}
		}
		.btnContactEtudiant {
			background-color: #5d0282;
			font-size: 16px;
			line-height: 1;
			text-transform: uppercase;
			color: #fff;
			padding: 16px 50px;
			border: 1px solid #5d0282;
			border-radius: 74px;
			cursor: pointer;
			text-decoration: none;
			display: flex;
		}
	}

	.container__Offer {
		padding: 48px 32px 32px;
		background: #f8f8fc;
		hr {
			margin: 30px 0;
		}
		&__titleOffre {
			text-align: center;
			line-height: 1.3;
			max-width: 100%;
			font-weight: 500;
			font-size: 38px;
			color: #5d0282;
			margin-bottom: 32px;
			&__subTitle {
				font-size: 27px;
				line-height: 130%;
				margin-bottom: 38px;
				text-transform: uppercase;
				text-align: center;
				max-width: 100%;
			}
		}
		.offreList {
			h4 {
				font-weight: 500;
				font-size: 18px;
				line-height: 130%;
				color: #06073a;
				font-family: "Inseec-Medium";
				margin: 20px 0;
			}
			.offreListDetails {
				display: flex;
				flex-wrap: wrap;
				box-sizing: border-box;

				&__option {
					font-family: Soleil-Regular; /*Can be any text*/

					width: calc(100% / 4 - 20px);
					box-sizing: border-box;
					justify-content: space-between;
					margin-bottom: 20px;
					h6 {
						font-weight: 600;
						font-size: 14px;
						line-height: 18px;
						color: #5d0282;
						margin-bottom: 8px;
					}
					p {
						font-size: 13px;
						line-height: 1;
						color: #190038;
					}
					.inline {
						display: inline;
						&:not(:last-child) {
							&:after {
								content: ", ";
							}
						}
					}
				}
				.subtitle {
					margin: 15px 0;
					text-transform: uppercase;
				}
			}
			.skills {
				display: block;
				.containerSkills {
					display: flex;
					gap: 20px;
					flex-wrap: wrap;
					font-weight: 600;

					padding-bottom: 20px;
					line-height: 20px;
					color: #190038;

					.indigoColor {
						color: #5d0282;
					}
					span {
						display: block;
						margin-bottom: 8px;
					}
					.multiCol {
						width: calc(20% - 16px);
						padding: 12px 8px;
						border: 1px solid #9f9eb7;
					}
				}
			}
		}
		.experience {
			h4 {
				font-weight: 500;
				font-size: 18px;
				line-height: 130%;
				color: #06073a;
				font-family: "Inseec-Medium"; /*Can be any text*/
			}
			.offreListDetails {
				padding: 16px 32px 16px 16px;
				border: 1px solid #9f9eb7;
				margin: 20px 0;
				.cols {
					display: flex;
					flex-wrap: wrap;
					gap: 15px 60px;
				}
				.subtitle {
					font-weight: 600;
					font-size: 16px;
					line-height: 20px;
					display: flex;
					align-items: center;
					color: #190038;
					margin-bottom: 8px;
				}
				&__option {
					width: calc(50% - 30px);
					h6 {
						font-weight: 600;
						font-size: 14px;
						line-height: 18px;
						color: #5d0282;
						margin-bottom: 8px;
					}
					p {
						font-size: 14px;
						line-height: 16px;
						color: #3e0056;
						display: flex;
						align-items: center;
						padding-bottom: 0;
					}
				}
			}
		}
	}

	.column {
		flex-direction: column;
	}
	.toApplay {
		display: flex;
		justify-content: right;
	}
}

.saveDocument_master_container {
	display: flex;
	flex-direction: column;
	padding-top: 8px;
}

.label_padding {
	padding-bottom: 0.5rem;
	font-family: Soleil-Regular;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.125rem;
	color: #5d0282;
}

// Responsive import
@import "./CvById.responsive";
