@import "variables";

.input_margin {
  margin: 0px;
  margin-bottom: 10px;
}

.output_container {
  width: 100%;
  position: absolute;
  top: bottom;
  z-index: 9;
  background-color: #fff;
  max-height: 300px;
  overflow-x: auto;
}

.output_plus_image_Container {
  display: flex;
  padding: 8px;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
  li {
    color: #4d4d4d;
    padding-left: 10px;
  }
  &:hover{
	background-color: #f0f0f0;
  }
}

.search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0% 4%;
}

.opco_group {
  position: relative;
}
.additionalClassNames{
 margin-bottom: 10px;
}
.submit_btn_to_right {
  display: flex;
  justify-content: end;
  padding-bottom: 32px;
  button {
    text-transform: lowercase;
    max-width: 320px;
  }
}
@media screen and (max-width: $mobile-breakpoint) {
  .wrapper {
    flex-direction: column;
    grid-template-columns: 1fr 
  }
}
