.user_info_card {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
  
    .label {
      font-family: Soleil-Regular;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #5d0282;
      margin-bottom: 12px;
    }
  
    .info_container {
      clear: left;
      position: relative;
      font-family: Soleil-Leger;
    }
  }