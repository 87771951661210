@import "variables";

.container {
  margin: 20px 60px;
  padding: 30px;
  background-color: #f8f8fc;
  border-radius: 8px;
}
.input_group_line {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 0 4%;
}

.group_margin {
  margin-top: 20px;
}

.siret_text {
  font-family: Soleil-Regular;
  font-size: 13px;
  margin-top: 5px;
}

.idcc_group {
  position: relative;
}
.search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}
.idcc_icon {
  position: absolute;
  width: 1.2rem;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: #999;
  cursor: pointer;
}

.idcc_group,
.input_group_line {
  .input_padding {
    input[type="text"] {
      padding-left: calc(32px + 1.2rem);
    }
  }
}

.input_margin {
  margin: 0px;
}

.output_container {
  width: 100%;
  position: absolute;
  top: bottom;
  z-index: 9;
  background-color: #fff;
  max-height: 300px;
  overflow-x: auto;
}

.output_plus_image_container {
  display: flex;
  padding: 8px;
  font-size: 13px;
  line-height: 1.5;
  cursor: pointer;
  li {
    color: #4d4d4d;
    padding-left: 10px;
  }
  &:hover {
    background-color: #f0f0f0;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .idcc_group {
    margin-bottom: 38px;
  }

  .container {
    .input_group_line {
      grid-template-columns: 1fr;
      grid-gap: 2px;
    }
    .wrapper {
      grid-template-columns: 1fr;
      gap: 0 4%;
      width: 100%;
      .formGroup {
        max-width: 100%;
      }
    }
    .info_label {
      display: flex;
    }
  }
  .infoBulle {
    position: relative;
    margin-left: 5px;
    left: auto;
    top: auto;
  }
  .submit_btn{
    display: flex;
    justify-content: center;
  }
}
