.main_container {
	padding: 0 60px 60px;
}
.title_container {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 550px;
	margin-bottom: 22px;
}

.form_container {
	display: flex;
	gap: 0 60px;
	align-items: flex-start;

	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-color: #f8f8fc;
		max-width: 550px;
		padding: 32px 0;
	}
}
.container {
	max-width: 550px;
	width: 100%;
}

.inputs_container {
	display: flex;
	flex-direction: column;
	width: 70%;
	margin-bottom: 24px;
	font-family: Soleil-Regular;

	label {
		font-weight: 600;
		font-size: 14px;
		line-height: 18px;
		color: #5d0282;
		margin-bottom: 12px;
	}

	.label_plus_input_container {
		display: flex;
		flex-direction: column;
		margin-bottom: 24px;
	}
}
