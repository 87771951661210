.card_container {
    width: 30%;
    background: #FFFFFF;
    box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
    border-radius: 5px;
    margin-bottom: 40px;
    text-decoration: none;

    &:hover {
        cursor: pointer;
    }

    img {
        width: 100%;
    }

    div {
        padding: 16px 20px 36px;
    }

    h3 {
        width: 100%;
        padding-bottom: 7px;
        padding-left: 0px;
        border-bottom: 1px solid #9F9EB7;
        margin-bottom: 20px;
    }

    p {
        font-size: 14px;
        line-height: 16px;
        color: #3E0056;
    }
}

// Responsive import
@import "./Card.responsive";