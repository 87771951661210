@import "variables";

// Mobile
@media screen and (max-width: $mobile-breakpoint) {
	.hideDesktop {
		font-size: 14px;
		line-height: 16px;
		color: #06073a;
		font-weight: bold;
		width: 244px;
		display: flex;
	}
	.containerResult {
		background: #f8f8fc;
		padding: 32px 16px;
		width: 100%;
		&__head {
			display: none;
		}
		.result {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			background-color: #ffffff;
			box-shadow: 0px 0px 40px rgb(0 0 0 / 8%);
			border-radius: 10px;
			margin-bottom: 30px;
			padding: 16px;
			border: none;
			&__body {
				padding: 0;
				display: flex;
				&__id {
					padding-bottom: 10px;
				}
				&__link {
					align-self: center;
					padding: 20px 0 0;
					.btn_seeMore {
						width: 200px;
					}
				}
			}
		}
	}
}
